import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";

const CreditScore = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  // eslint-disable-next-line
  const [clients, setClients] = useState([]);
  const controller = new AbortController();
  const getClients = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get("/reports/new/credit-score", {
        signal: controller.signal,
      });
      console.log(response.data.data.data);
      setClients(response.data.data.data);

      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Credit Score{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3">
          <div className="row mt-3">
            <div className="col-md-12 mb-3">
              <form className="row needs-validation" method="post">
                <div className="form-group col-md-4">
                  <label id="" className="form-label">
                    Select a Member
                  </label>
                  <select
                    name="selectMember"
                    id=""
                    data-live-search="true"
                    data-width="100%"
                    className="border-1 form-control-md input-text"
                    required
                  >
                    <option disabled selected hidden>
                      Select a Member
                    </option>
                  </select>
                </div>

                <div className="form-group col-md-2 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center mt-3 rounded-0 login"
                  >
                    Generate
                  </button>
                  <div className="loading p-2 col-xs-1" align="right">
                    <div className="loader"></div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-md-6">
              <table className="table table-hover table-bordered text-medium-small">
                <thead>
                  <tr>
                    <th colspan="2">Personal Information</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Name</th>
                    <td></td>
                  </tr>

                  <tr>
                    <th>Account Number</th>
                    <td></td>
                  </tr>

                  <tr>
                    <th>Account Status</th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <table className="table table-hover table-bordered text-medium-small">
                <thead>
                  <tr>
                    <th colspan="2">Savings Summary (Last 12 Months)</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Savings Made</th>
                    <td>UGX 0</td>
                  </tr>

                  <tr>
                    <th>Withdraws Made</th>
                    <td>UGX 0</td>
                  </tr>

                  <tr>
                    <th>Savings Balance</th>
                    <td>UGX 0</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6">
              <table className="table table-hover table-bordered text-medium-small">
                <thead>
                  <tr>
                    <th colspan="2">Loan History</th>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th>Active</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Completed</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Defaulted</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Rejected</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Waived</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Written Off</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Total number of loans applied</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>History of default</th>
                    <td>0</td>
                  </tr>

                  <tr>
                    <th>Loans Cleared on Time</th>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Outstanding loan amounts</th>
                    <td>UGX 0</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="col-md-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditScore;
