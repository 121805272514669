import Logo from "assets/images/img/logo.png";
import { Link } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import useLogout from "middlewares/hooks/useLogout";
const Header = memo(() => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState([]);
  const controller = new AbortController();
  const getUser = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco/default/header`, {
        signal: controller.signal,
      });

      setUser(response?.data?.data?.data);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   const logout = useLogout();
   const signOut = async () => {
     if (window.confirm("Are you sure you want to logout?")) {
       await logout();
       await localStorage.removeItem("verify");
       navigate("/");
     }
   };
  return (
    <>
      <div className="header sticky-top">
        <nav className="navbar navbar-expand-lg px-4 py-2 bg-white shadow">
          <Link
            to="#"
            className="sidebar-toggler text-gray-500 mr-4 mr-lg-5 lead"
          >
            <i className="fas fa-align-left"></i>
          </Link>
          <p className="text-sm">
            <b> Sacco Name: </b> {user.sacco_name}{" "}
          </p>
          <Link
            to="/dashboard"
            className="navbar-brand font-weight-bold text-uppercase text-base"
          >
            <img
              className="img-fluid"
              style={{ maxHeight: "5rem" }}
              src={Logo}
              alt="Irembo Finance"
            />
          </Link>
          <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">
            <li className="nav-item">
              <div className="d-none d-lg-block">
                <div className="position-relative mb-0">
                  <p className="p-2 mt-3 mr-0  text-sm">
                    <b>SACCO Code:</b> {user.ussd}{" "}
                    <b className="ml-3">Branch:</b> {user.branch_name}
                  </p>
                </div>
              </div>
            </li>
            {/* <li className="nav-item dropdown mr-3">
              <Link
                id="notifications"
                to=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="nav-link dropdown-toggle text-gray-400 px-1"
              >
                <i className="fa fa-bell"></i>
                <span className="notification-icon"></span>
              </Link>
              <div aria-labelledby="notifications" className="dropdown-menu">
                <Link to="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-violet text-white">
                      <i className="fas fa-money-bill-alt"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Savings (0)</p>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-green text-white">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Message (0)</p>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-blue text-white">
                      <i className="fas fa-upload"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Member (0)</p>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-danger text-white">
                      <i className="fas fa-money-check"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Withdraws (0)</p>
                    </div>
                  </div>
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="#" className="dropdown-item text-center">
                  <small className="font-weight-bold headings-font-family text-uppercase">
                    Notifications(0)
                  </small>
                </Link>
              </div>
            </li> */}
            <li className="nav-item dropdown ml-auto">
              <Link
                id="userInfo"
                to="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="nav-link dropdown-toggle"
              >
                <img
                  src={"/logo.png"}
                  alt="Innocent Bigega"
                  style={{ maxHeight: "2.5rem" }}
                  className="rounded-circle shadow"
                />
              </Link>
              <div aria-labelledby="userInfo" className="dropdown-menu">
                <Link to="#" className="dropdown-item">
                  <strong className="d-block text-uppercase headings-font-family">
                    {user?.fullname}
                  </strong>
                  <small>{user?.email}</small> <br />
                  <small>{user?.contact}</small>
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="/profile/user" className="dropdown-item">
                  Settings
                </Link>
                <Link to="/activity" className="dropdown-item">
                  Activity log
                </Link>
                <div className="dropdown-divider"></div>
                <Link
                  to="#"
                  onClick={signOut}
                  className="dropdown-item"
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
});

export default Header;
