import {MaterialReactTable} from "material-react-table";
import { useState, useMemo, useEffect, useCallback } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DepositReceipt from "pages/accounting/savings/components/receipt_deposit";
import WithdrawReceipt from "pages/accounting/withdraws/components/receipt_withdraw";
const Transactions = ({
  showSavingModal,
  handleSavingClose,
  showWithdrawModal,
  handleWithdrawClose,
  getAccountBalance,
}) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const controller = new AbortController();

  const getAccounts = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounts/attached/accounts/${params.id}`,
        {
          signal: controller.signal,
        }
      );
      setAccounts(response.data.data.accounts);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = accounts
    ?.filter((account) => account.type === "savings")
    ?.map((account) => ({
      value: `${account.id}`,
      label: `${account.title}`,
    }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    control: control1,
    formState: { errors: errors1 },
  } = useForm();

  const [savings, setSavings] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const getSavings = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounting/savings/${params.id}/deposits`,
        {
          signal: controller.signal,
        }
      );

      setSavings(response?.data?.data?.transactions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSavings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getWithdrawals = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounting/withdraw/${params.id}/withdraws`,
        {
          signal: controller.signal,
        }
      );

      setWithdrawals(response?.data?.data?.transactions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getWithdrawals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetele = useCallback(
    (id) => {
      confirmAlert({
        // : 'Confirm to submit',
        message: " Are you sure to do this..",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const controller = new AbortController();
                const response = await axiosPrivate.delete(
                  `accounting/savings/deposits/${id}`,
                  {
                    signal: controller.signal,
                  }
                );
                toast.success(response?.data?.messages);
                await getSavings();
              } catch (error) {
                if (!error?.response) {
                  toast("No server response");
                } else {
                  console.log(error?.response);
                  if (error?.response?.status === 401) {
                    navigate("/", {
                      state: { from: location },
                      replace: true,
                    });
                  }
                  toast.error(error?.response?.data?.messages, {
                    style: {
                      background: "#e91e63",
                      fontSize: "13px",
                      color: "#fff",
                    },
                  });
                }
              }
            },
          },
          {
            label: "No",
            // onClick: () => alert('Click No')
          },
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleDetele1 = useCallback(
    (id) => {
      confirmAlert({
        // : 'Confirm to submit',
        message: " Are you sure to do this..",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const controller = new AbortController();
                const response = await axiosPrivate.delete(
                  `accounting/withdraw/withdraws/${id}`,
                  {
                    signal: controller.signal,
                  }
                );
                toast.success(response?.data?.messages);
                await getWithdrawals();
              } catch (error) {
                if (!error?.response) {
                  toast("No server response");
                } else {
                  console.log(error?.response);
                  if (error?.response?.status === 401) {
                    navigate("/", {
                      state: { from: location },
                      replace: true,
                    });
                  }
                  toast.error(error?.response?.data?.messages, {
                    style: {
                      background: "#e91e63",
                      fontSize: "13px",
                      color: "#fff",
                    },
                  });
                }
              }
            },
          },
          {
            label: "No",
            // onClick: () => alert('Click No')
          },
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const savingsColumns = useMemo(
    () => [
      {
        accessorKey: "account", //access nested data with dot notation
        header: "Account Title",
        size: 50,
      },
      {
        id: "amount", //access nested data with dot notation
        header: "Amount",
        size: 50,
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        accessorKey: "method", //access nested data with dot notation
        header: "Method",
        size: 50,
      },
      {
        id: "status",
        size: 50, //access nested data with dot notation
        header: "Status",
        accessorFn: (row) =>
          row.status === "success" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : row.status === "pending" ? (
            <div className="badge badge-info p-1 text-white">Pending</div>
          ) : row.status === "failed" ? (
            <div className="badge badge-danger p-1 text-white">Failed</div>
          ) : (
            ""
          ),
      },
      {
        id: "reverse_status",
        size: 50, //access nested data with dot notation
        header: "Reverse Status",
        accessorFn: (row) =>
          row.reverse_status === "ongoing" ? (
            <div className="badge badge-success p-1 text-white">
              Not Reversed
            </div>
          ) : row.reverse_status === "reversed" ? (
            <div className="badge badge-warning p-1 text-white">Reversed</div>
          ) : (
            ""
          ),
      },

      {
        accessorKey: "timestamp",
        size: 50, //access nested data with dot notation
        header: "Date",
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="float-left text-left text-sm">
            <button
              className="btn btn-danger text-white rounded-0 btn-sm p-1"
              onClick={() => handleDetele(row.id)}
              aria-expanded="false"
            >
              Reverse
            </button>
          </div>
        ),
      },
    ],
    [handleDetele]
  );
  const withrawColumns = useMemo(
    () => [
      {
        accessorKey: "account",
        size: 50, //access nested data with dot notation
        header: "Account Title",
      },
      {
        id: "amount", //access nested data with dot notation
        header: "Amount",
        size: 50,
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "charge", //access nested data with dot notation
        header: "Charge",
        size: 50,
        accessorFn: (row) => row.charge.toLocaleString(),
      },
      {
        accessorKey: "method", //access nested data with dot notation
        header: "Method",
        size: 50,
      },
      {
        id: "status",
        size: 50, //access nested data with dot notation
        header: "Status",
        accessorFn: (row) =>
          row.status === "success" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : row.status === "pending" ? (
            <div className="badge badge-info p-1 text-white">Pending</div>
          ) : row.status === "failed" ? (
            <div className="badge badge-danger p-1 text-white">Failed</div>
          ) : (
            ""
          ),
      },
      {
        id: "reverse_status", //access nested data with dot notation
        header: "Reverse Status",
        size: 50,
        accessorFn: (row) =>
          row.reverse_status === "ongoing" ? (
            <div className="badge badge-success p-1 text-white">
              Not Reversed
            </div>
          ) : row.reverse_status === "reversed" ? (
            <div className="badge badge-warning p-1 text-white">Reversed</div>
          ) : (
            ""
          ),
      },

      {
        accessorKey: "timestamp",
        size: 50, //access nested data with dot notation
        header: "Date",
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        size: 50,
        accessorFn: (row) => (
          <div className="float-left text-left text-sm">
            <button
              className="btn btn-danger text-white rounded-0 btn-sm p-1"
              onClick={() => handleDetele1(row.id)}
              aria-expanded="false"
            >
              Reverse
            </button>
          </div>
        ),
      },
    ],
    [handleDetele1]
  );
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const handleCloseModal = () => {
    setShowModal(false);
    setModalData([]);
  };
  const onSaving = async (data) => {
    var account = data.account.value;
    delete data.account;
    data.account = account;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/accounting/savings/deposits",
        data,
        {
          signal: controller.signal,
        }
      );
      await getSavings();
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getAccountBalance();
      handleSavingClose();
      reset();
      handleReceiptDepositOpenModal(response?.data?.data?.transaction);
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const onWithdraw = async (data) => {
    var account = data.account.value;
    delete data.account;
    data.account = account;
    // console.log(data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/accounting/withdraw/withdraws",
        data,
        {
          signal: controller.signal,
        }
      );
      await getWithdrawals();
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getAccountBalance();
      handleWithdrawClose();
      reset1();
      handleReceiptWithdrawOpenModal(response?.data?.data?.transaction);
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [showReceiptDepositModal, setShowReceiptDepositModal] = useState(false);
  const [modalReceiptDepositData, setModalReceiptDepositData] = useState([]);

  const handleReceiptDepositOpenModal = (data) => {
    setShowReceiptDepositModal(true);
    setModalReceiptDepositData(data);
  };
  const handleReceiptDepositCloseModal = () => {
    setShowReceiptDepositModal(false);
    setModalReceiptDepositData([]);
  };
  const [showReceiptWithdrawModal, setShowReceiptWithdrawModal] =
    useState(false);
  const [modalReceiptWithdrawData, setModalReceiptWithdrawData] = useState([]);

  const handleReceiptWithdrawOpenModal = (data) => {
    setShowReceiptWithdrawModal(true);
    setModalReceiptWithdrawData(data);
  };
  const handleReceiptWithdrawCloseModal = () => {
    setShowReceiptWithdrawModal(false);
    setModalReceiptWithdrawData([]);
  };
  return (
    <>
      <Toaster />
      <DepositReceipt
        showModal={showModal}
        handleClose={handleCloseModal}
        data={modalData}
      />
          <div className="card-header">Savings Transactions</div>
          <div className="card-body p-0">
            <MaterialReactTable
              columns={savingsColumns}
              data={savings}
              className="table align-items-center p-0"
              enableColumnFilterModes
              enableColumnOrdering
              enableRowSelection
              enableGrouping
              enableStickyHeader
              initialState={{ density: "compact" }}
              muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "normal",
                  fontSize: "13px",
                  fontFamily: "Nunito",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  fontWeight: "normal",
                  fontSize: "13px",
                  fontFamily: "Nunito",
                },
              }}
              muiSearchTextFieldProps={{
                placeholder: "Search",
                sx: { fontFamily: "Nunito", fontSize: "12px" },
              }}
            />
            <DepositReceipt
              showModal={showReceiptDepositModal}
              handleClose={handleReceiptDepositCloseModal}
              data={modalReceiptDepositData}
            />
          </div>
       
          <div className="card-header mt-5">Withdrawal Transactions</div>
          <div className="card-body p-0">
            <MaterialReactTable
              columns={withrawColumns}
              data={withdrawals}
              className="table align-items-center p-0"
              enableColumnFilterModes
              enableColumnOrdering
              enableRowSelection
              enableGrouping
              enableStickyHeader
              initialState={{ density: "compact" }}
              muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: "normal",
                  fontSize: "13px",
                  fontFamily: "Nunito",
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  fontWeight: "normal",
                  fontSize: "13px",
                  fontFamily: "Nunito",
                },
              }}
              muiSearchTextFieldProps={{
                placeholder: "Search",
                sx: { fontFamily: "Nunito", fontSize: "12px" },
              }}
            />
            <WithdrawReceipt
              showModal={showReceiptWithdrawModal}
              handleClose={handleReceiptWithdrawCloseModal}
              data={modalReceiptWithdrawData}
            />
          </div>
      <Modal
        show={showSavingModal}
        backdrop="static"
        keyboard={false}
        onHide={handleSavingClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Make New Savings
          </Modal.Title>
          <button onClick={handleSavingClose} className="btn-close btn-danger">
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit(onSaving)} autoComplete="false">
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-6 input-container">
                <label htmlFor="account" className="form-label">
                  Select Account
                </label>
                <Controller
                  control={control}
                  name="account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register("account", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Accounts To Save * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      ref={ref}
                    />
                  )}
                />
                {errors?.account?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select Account
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="amount" className="form-label">
                  Enter Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter Amount *"
                  autoComplete="off"
                  className="form-control border-0 shadow form-control-md input-text"
                  {...register("amount", { required: true })}
                />

                {errors?.amount?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Amount
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="notary" className="form-label">
                  Deposited By{" "}
                </label>
                <input
                  type="text"
                  name="notary"
                  placeholder="Enter Depositor *"
                  className="form-control border-0 shadow form-control-md input-text"
                  autoComplete="new-password"
                  role="presentation"
                  rows={5}
                  {...register("notary", { required: true })}
                />

                {errors?.notary?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter depositor fullname
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="method" className="form-label">
                  Method
                </label>
                <select
                  type="text"
                  name="method"
                  className="form-control border-0 shadow form-control-lg input-text"
                  {...register("method", { required: true })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Method
                  </option>
                  <option value="cash">Cash</option>
                  <option value="mobile_money">Mobile Money</option>
                  <option value="bank">Bank</option>
                </select>

                {errors?.method?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter depositor fullname
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="notes" className="form-label">
                  Notes
                </label>
                <textarea
                  type="text"
                  name="notes"
                  placeholder="Enter Notes"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={1}
                  autoComplete="none"
                  defaultValue="Savings"
                  {...register("notes", { required: false })}
                ></textarea>
                <input
                  type="hidden"
                  value={params.id}
                  {...register("client", { required: true })}
                />
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="pincode" className="form-label">
                  Enter Pincode
                </label>
                <input
                  type="password"
                  name="pincode"
                  placeholder="Enter Pincode *"
                  className="form-control border-0 shadow form-control-md input-text"
                  autoComplete="new-password"
                  role="presentation"
                  {...register("pincode", { required: true })}
                />

                {errors?.pincode?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Pincode
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleSavingClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showWithdrawModal}
        backdrop="static"
        keyboard={false}
        onHide={handleWithdrawClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            A New Withdraw
          </Modal.Title>
          <button
            onClick={handleWithdrawClose}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form
          noValidate
          onSubmit={handleSubmit1(onWithdraw)}
          autoComplete="off"
        >
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-6 input-container">
                <label htmlFor="account" className="form-label">
                  Select Account
                </label>
                <Controller
                  control={control1}
                  name="account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register1("account", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Accounts To Withdraw * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      isClearable={true}
                      ref={ref}
                    />
                  )}
                />
                {errors1?.account?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select Account
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="amount" className="form-label">
                  Enter Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter Amount *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register1("amount", { required: true })}
                />

                {errors1?.amount?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Amount
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="notary" className="form-label">
                  Withdraw By{" "}
                </label>
                <input
                  type="text"
                  name="notary"
                  placeholder="Enter Withdraw By *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  autoComplete="new-password"
                  {...register1("notary", { required: true })}
                />

                {errors1?.notary?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter depositor fullname
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="method" className="form-label">
                  Method
                </label>
                <select
                  type="text"
                  name="method"
                  className="form-control border-0 shadow form-control-lg input-text"
                  {...register1("method", { required: true })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Method
                  </option>
                  <option value="cash">Cash</option>
                  <option value="mobile_money">Mobile Money</option>
                  <option value="bank">Bank</option>
                </select>

                {errors1?.method?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter depositor fullname
                  </p>
                )}
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="notes" className="form-label">
                  Notes
                </label>
                <textarea
                  type="text"
                  name="notes"
                  placeholder="Enter Notes"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={1}
                  defaultValue="Withdraws"
                  {...register1("notes", { required: false })}
                ></textarea>
                <input
                  type="hidden"
                  value={params.id}
                  {...register1("client", { required: true })}
                  autoComplete="off"
                />
              </div>
              <div className="form-group col-md-6 input-container">
                <label htmlFor="pincode" className="form-label">
                  Enter Pincode
                </label>
                <input
                  type="password"
                  name="pincode"
                  placeholder="Enter Pincode *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  autoComplete="new-password"
                  {...register1("pincode", { required: true })}
                />

                {errors?.pincode?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Pincode
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleWithdrawClose}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      
    </>
  );
};

export default Transactions;
