import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";

const BalanceSheet = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const [data, setData] = useState([]);
  const controller = new AbortController();
  const getData = async () => {
    progress.start();
    try {
      const response = await axiosPrivate.get("/reports/new/balance-sheet", {
        signal: controller.signal,
      });
      console.log(response.data.data.data);
      setData(response.data.data.data);

      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sumIncome = data?.assets
    ?.map((data) => parseFloat(data?.account_balance))
    ?.reduce((a, b) => a + b, 0);
  const sumExpenses = data?.liabilities
    ?.map((data) => parseFloat(data?.account_balance))
    ?.reduce((a, b) => a + b, 0);
    const sumEquity = data?.equity
      ?.map((data) => parseFloat(data?.account_balance))
      ?.reduce((a, b) => a + b, 0);
  // const sumIncome = [];
  // const sumExpenses = [];
  return (
    <>
      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Balance Sheet{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3">
          <div className="mt-3">
            <table className="table table-hover table-bordered">
              <tbody>
                <tr>
                  <th
                    className="bg-dark text-white text-medium-small"
                    colSpan={2}
                  >
                    Assets
                  </th>
                </tr>
                {data?.assets?.map((vote, index) => (
                  <tr key={index} className="text-medium-small">
                    <td>{vote?.account_title}</td>
                    <td>
                      {parseFloat(vote?.account_balance)?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-light text-medium-small">Total Assets</th>
                  <th className="bg-light text-medium-small">
                    {sumIncome?.toLocaleString()}
                  </th>
                </tr>
                <tr>
                  <th
                    className="bg-dark text-white text-medium-small"
                    colSpan={2}
                  >
                    Liabilites
                  </th>
                </tr>
                {data?.liabilities?.map((vote, index) => (
                  <tr key={index} className="text-medium-small">
                    <td>{vote?.account_title}</td>
                    <td>
                      {parseFloat(vote?.account_balance)?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-light text-medium-small">
                    Total Liabilites
                  </th>
                  <th className="bg-light text-medium-small">
                    {sumExpenses?.toLocaleString()}
                  </th>
                </tr>

                <tr>
                  <th
                    className="bg-dark text-white text-medium-small"
                    colSpan={2}
                  >
                    Equity / Capital
                  </th>
                </tr>
                {data?.equity?.map((vote, index) => (
                  <tr key={index} className="text-medium-small">
                    <td>{vote?.account_title}</td>
                    <td>
                      {parseFloat(vote?.account_balance)?.toLocaleString()}
                    </td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-light text-medium-small">Total Equity</th>
                  <th className="bg-light text-medium-small">
                    {sumEquity?.toLocaleString()}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceSheet;
