import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";

const GeneralLedger = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

   const customStyles = {
     control: (provided) => ({
       ...provided,
       height: "45px", // adjust the height as needed
     }),
   };
   const controller = new AbortController();

   const [accounts, setAccounts] = useState([]);
   //fetch Accounts Accounts data
   const getAccounts = async () => {
     try {
       const response = await axiosPrivate.get("settings/accounts/account", {
         signal: controller.signal,
       });

       setAccounts(response.data.data.accounts);
     } catch (error) {
       // console.log(error)
       if (error?.response?.status === 401) {
         navigate("/", { state: { from: location }, replace: true });
       }
     }
   };
   useEffect(() => {
     getAccounts();
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
const accountsData = accounts?.map((account) => ({
  value: account.id,
  label: account.title + " | " + account.code,
}));
  const [dates, setDates] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [applicationAmount, setApplicationAmount] = useState([]);
  const [shares, setShares] = useState([]);
  const [accountCharges, setAccountCharges] = useState([]);
  const [withdrawCharges, setWithdrawCharges] = useState([]);
  const [loanCharges, setLoanCharges] = useState([]);
  const [accountTopUp, setAccountTopUp] = useState([]);
  const [loanRepayment, setLoanRepayment] = useState([]);
  const [generalAccount, setGeneralAccount] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/reports/new/general-ledger`,
        data,
        {
          signal: controller.signal,
        }
      );
      setDates(response.data.data.dates);
      setGeneralAccount(response.data.data.account);
      setIncomes(response.data.data.data.incomes);
      setDeposits(response.data.data.data.deposits);
      setExpenses(response.data.data.data.expenses);
      setWithdraws(response.data.data.data.withdraws);
      setWithdrawCharges(response.data.data.data.withdrawCharges);
      setApplicationAmount(response.data.data.data.applications);
      setShares(response.data.data.data.shares);
      setAccountCharges(response.data.data.data.accountCharges);
      setLoanCharges(response.data.data.data.loanCharges);
      setAccountTopUp(response.data.data.data.accountTop);
      setLoanRepayment(response.data.data.data.loanRepayment);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const data = [
    ...incomes,
    ...deposits,
    ...shares,
    ...accountCharges,
    ...loanCharges,
    ...accountTopUp,
    ...loanRepayment,
    ...expenses,
    ...applicationAmount,
    ...withdraws,
    ...withdrawCharges,
  ];
    
  const datacredit = [
    ...incomes,
    ...deposits,
    ...shares,
    ...accountCharges,
    ...loanCharges,
    ...accountTopUp,
    ...loanRepayment,
    ...withdrawCharges,
  ];
  const datadebit = [...expenses, ...applicationAmount, ...withdraws];
  
  const sumcredit = datacredit
    .filter((data) => data.type === "credit")
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  const sumdebit = datadebit
    .filter((data) => data.type === "debit")
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  //   console.log(sumcredit)

  const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
  //   console.log(data)
  return (
    <>
      <Toaster />

      <div className="card mt-2">
        <div className="card-header bg-dark">
          <h2 className="h5 text-bold text-uppercase text-center text-white">
            General Ledger{" "}
          </h2>
        </div>
        <div className="card-body mt-3 mb-3 text-medium-small">
          <div className="row">
            <div className="col-md-12 mb-3">
              <form
                className="row needs-validation"
                id="generalLegerForm"
                onSubmit={handleSubmit(onQuery)}
              >
                <div className="form-group col-md-3">
                  <label className="form-label">Select Start Date</label>
                  <input
                    type="date"
                    placeholder="Select Start Date"
                    className="form-control shadow border-1"
                    {...register("start_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.start_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label">Select End Date</label>
                  <input
                    type="date"
                    placeholder="Select End Date"
                    className="form-control shadow border-1"
                    {...register("end_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.end_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label">Select Vote</label>
                  <Controller
                    control={control}
                    name="account"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        {...register("account", {
                          required: true,
                        })}
                        options={accountsData}
                        onChange={onChange}
                        isMulti={false}
                        onBlur={onBlur}
                        name={name}
                        className="shadow border-0"
                        value={value}
                        styles={customStyles}
                        ref={ref}
                      />
                    )}
                  />
                  {errors?.account?.type === "required" && (
                    <p className="text-small m-0 text-start text-danger help-block">
                      Please select vote
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3 pt-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center rounded-0"
                    disabled={disabled}
                  >
                    {!disabled && "Generate"} {disabled && "Please Wait ....."}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-3 text-medium-small">
            <table className="table table-hover table-bordered text-medium-small">
              <thead>
                <tr>
                  <th className="text-left" colSpan="8">
                    <div className="row">
                      <p className="text-left col-4">
                        Vote Title: {generalAccount}
                      </p>
                      <p className="text-left col-4">
                        Start Date : {dates?.start_date}
                      </p>
                      <p className="text-left col-4">
                        End Date: {dates?.end_date}
                      </p>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>Title / Vote</th>
                  <th>Date</th>
                  <th>Comment</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>

              <tbody>
                {sortedData?.map((row, index) =>
                  row.type === "credit" ? (
                    <tr key={index}>
                      <td>{row?.title}</td>
                      <td>
                        {new Date(row?.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td>{row?.comment}</td>
                      <td></td>
                      <td>{row?.amount?.toLocaleString()}</td>
                    </tr>
                  ) : row.type === "debit" ? (
                    <tr key={index}>
                      <td>{row.title}</td>
                      <td>
                        {new Date(row?.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td>{row?.comment}</td>
                      <td>{row?.amount?.toLocaleString()}</td>
                      <td></td>
                    </tr>
                  ) : (
                    ""
                  )
                )}

                <tr>
                  <th>Total</th>
                  <th colSpan={2}></th>
                  <th>{sumdebit.toLocaleString()}</th>
                  <th>{sumcredit.toLocaleString()}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralLedger;
