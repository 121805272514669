import {MaterialReactTable} from "material-react-table";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
const AccountSharesGeneral = ({ getData, shares, share_balance, accounts }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [disabled, setDisabled] = useState(false);
  const [clients, setClients] = useState([]);
  const controller = new AbortController();

  const getClients = async () => {
    try {
      const response = await axiosPrivate.get(`clients/general`, {
        signal: controller.signal,
      });
      setClients(response.data.data.clients);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = clients?.map((client) => ({
    value: `${client.id}`,
    label: `${client.name} - ${client.contact}`,
  }));
  const options2 = accounts?.map((account) => ({
      value: `${account.id}`,
      label: `${account.title}`

  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    control: control2,
    formState: { errors: errors2 },
  } = useForm();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const sharesColumns = useMemo(
    () => [
      {
        accessorKey: "count",
        size: 50, //access nested data with dot notation
        header: "Shares",
      },
      {
        id: "code", //access nested data with dot notation
        header: "Transaction Code",
        size: 50,
        accessorFn: (row) => row.code,
      },
      {
        id: "share_amount", //access nested data with dot notation
        header: "Share Amount",
        size: 50,
        accessorFn: (row) => row.share_amount.toLocaleString(),
      },
      {
        id: "reference", //access nested data with dot notation
        header: "Reference",
        size: 100,
        accessorFn: (row) => row.reference,
      },
      {
        id: "status",
        size: 50, //access nested data with dot notation
        header: "Status",
        accessorFn: (row) =>
          row.status === "in" ? (
            <div className="badge badge-success p-1 text-white">In</div>
          ) : row.status === "out" ? (
            <div className="badge badge-danger p-1 text-white">Out</div>
          ) : (
            ""
          ),
      },
      {
        accessorKey: "timestamp", //access nested data with dot notation
        header: "Date",
        size: 50,
      },

      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        size: 50,
        accessorFn: (row) => (
          <div className="float-left text-left text-sm">
            <button
              className="btn btn-danger text-white rounded-0 btn-sm p-1"
              aria-expanded="false"
            >
              Reverse
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const [sharesHistory, setShareHistory] = useState([]);
  const getShareHistory = async () => {
    try {
      const response = await axiosPrivate.get(
        `/accounting/shares/${params.id}`,
        {
          signal: controller.signal,
        }
      );
      setShareHistory(response.data.data.shares);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getShareHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  const onSave = async (data) => {
    data.client = params.id
    data.account = data.account.value;
    // console.log(data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("/accounting/shares", data, {
        signal: controller.signal,
      });
      await getShareHistory();
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      getData();
      reset2();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [showModalTransfers, setShowModalTransfers] = useState(false);
  const handleShowModalTransfers = () => setShowModalTransfers(true);
  const handleCloseModalTransfers = () => setShowModalTransfers(false);

  const onSaveTransfer = async (data) => {
    data.client_sending = params.id;
    var client = data.client.value;
    delete data.client;
    data.client = client;
    // console.log(data);
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.put("/accounting/shares", data, {
        signal: controller.signal,
      });
      await getShareHistory();
      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      handleCloseModalTransfers();
      reset();
      getData();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: {
            background: "#e91e63",
            fontSize: "13px",
            color: "#fff",
          },
        });
      }
    }
  };
  return (
    <>
      <Toaster />

          <div className="card">
            <div className="card-header">
              <div className="row">
              <div className="col-md-2">Share History</div>
              <div className="col-md-2">Shares: {shares} </div>
              <div className="col-md-2">
                Share Balance: {share_balance?.toLocaleString()}
              </div>
              <div className="col-md-3">
                <button
                  onClick={handleShowModal}
                  className="btn btn-sm btn-primary rounded-0 float-right"
                >
                  Add Shares
                </button>
              </div>
              <div className="col-md-3">
                <button
                  onClick={handleShowModalTransfers}
                  className="btn btn-sm btn-danger rounded-0"
                >
                  Transfer Shares
                </button>
              </div>
              </div>
            </div>
            <div className="card-body p-0">
              <MaterialReactTable
                columns={sharesColumns}
                data={sharesHistory}
                className="table align-items-center"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                initialState={{ density: "compact" }}
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              />
            </div>
          </div>

      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            New Shares
          </Modal.Title>
          <button onClick={handleCloseModal} className="btn-close btn-danger">
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form noValidate onSubmit={handleSubmit2(onSave)} autoComplete="off">
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12 input-container">
                <label htmlFor="account" className="form-label">
                  Select Account
                </label>
                <Controller
                  control={control2}
                  name="account"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register2("account", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Account * "
                      styles={customStyles}
                      options={options2}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      isClearable={true}
                      ref={ref}
                    />
                  )}
                />

                {errors2?.account?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please Select Account
                  </p>
                )}
              </div>
              <div className="form-group col-md-12 input-container">
                <label htmlFor="numberOfShares" className="form-label">
                  Number Of Shares
                </label>
                <input
                  type="number"
                  name="numberOfShares"
                  placeholder="Enter Number Of Shares *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register2("numberOfShares", { required: true })}
                />

                {errors2?.numberOfShares?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please Enter Number Of Shares
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-primary"
            >
              {!disabled && "Save"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showModalTransfers}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModalTransfers}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Transfer Share
          </Modal.Title>
          <button
            onClick={handleCloseModalTransfers}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>
        <form
          noValidate
          onSubmit={handleSubmit(onSaveTransfer)}
          autoComplete="off"
        >
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12 input-container">
                <label htmlFor="client" className="form-label">
                  Select Receiving Member
                </label>
                <Controller
                  control={control}
                  name="client"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <Select
                      {...register2("client", { required: true })}
                      className="border-1 form-control-md"
                      placeholder="Select Receiving Member * "
                      styles={customStyles}
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      isMulti={false}
                      isClearable={true}
                      ref={ref}
                    />
                  )}
                />
                {errors?.client?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please select Member
                  </p>
                )}
              </div>

              <div className="form-group col-md-12 input-container">
                <label htmlFor="numberOfShares" className="form-label">
                  Number Of Shares
                </label>
                <input
                  type="number"
                  name="numberOfShares"
                  placeholder="Enter Number Of Shares *"
                  className="form-control border-0 shadow form-control-md input-text"
                  rows={5}
                  {...register("numberOfShares", { required: true })}
                />

                {errors?.numberOfShares?.type === "required" && (
                  <p className="text-small m-0 px-1 text-start text-danger help-block">
                    Please enter Number Of Shares
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-dark"
              onClick={handleCloseModalTransfers}
            >
              Close
            </button>
            <button
              disabled={disabled}
              type="submit"
              className="btn btn-danger"
            >
              {!disabled && "Save"} {disabled && "Please Wait ... "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AccountSharesGeneral;
