import TellerDashBoard from "./components/teller-dashboard";
// import LoansDashBoard from "./components/loans-dashboard";
// import ManagerDashboard from "./components/manager-dashboard";
import GreetingComponent from "./components/greeting";

const Dashboard = () => {
  const user_info = JSON.parse(localStorage["user_data"]);
   
  return (
    <>
      <section className="row">
        <div className="col-xl-12 mb-2 mt-2">
          <div className="card bg-hover-gradient-success bg-hover-danger bg-gradient-info p-3">
            <div className="row">
              <p className="h5 col-xl-6 text-start">
                {" "}
                <GreetingComponent />, {`${user_info?.firstname} 
                ${user_info?.lastname}`}
              </p>
              <p className="h6 col-xl-6 text-end">
                You are signed as, {user_info?.role}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-2">
        <TellerDashBoard />
      </section>
      {/* <section className="py-2">
            <LoansDashBoard />
          </section>
          <section className="py-2">
            <ManagerDashboard />
          </section> */}
    </>
  );
};

export default Dashboard;
