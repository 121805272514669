import {MaterialReactTable} from "material-react-table";
import { useState, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import WithdrawReceipt from "./components/receipt_withdraw";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import ConfirmModal from "pages/components/confirmModal";

const WithdrawGeneral = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleOpenModal = (data) => {
    setShowModal(true);
    setModalData(data);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setModalData([]);
  };
  // eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState([]);
  // eslint-disable-next-line
  const [globalFilter, setGlobalFilter] = useState("");
  // eslint-disable-next-line
  const [sorting, setSorting] = useState([]);
  // eslint-disable-next-line
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data: tableData = [], meta } = {}, // your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "table-data",
      columnFilters, // refetch when columnFilters changes
      globalFilter, // refetch when globalFilter changes
      pagination.pageIndex, // refetch when pagination.pageIndex changes
      pagination.pageSize, // refetch when pagination.pageSize changes
      sorting, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `serverside/withdraws`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination.pageIndex * pagination.pageSize,
            size: pagination.pageSize,
            filters: JSON.stringify(columnFilters ?? []),
            globalFilter: globalFilter ?? "",
            sorting: JSON.stringify(sorting ?? []),
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
    const [show, setShow] = useState(false);
    const [id, setId] = useState([]);

   const handleCancel = async () => {
     setId([]);
   };
   const handleDelete = async () => {
     try {
       const controller = new AbortController();
       const response = await axiosPrivate.delete(
         "accounting/withdraw/withdraws/" + id,
         {
           signal: controller.signal,
         }
       );
       toast.success(response?.data?.messages);
       await refetch();
     } catch (error) {
       if (!error?.response) {
         toast("No server response");
       } else {
         console.log(error?.response);
         if (error?.response?.status === 401) {
           navigate("/", {
             state: { from: location },
             replace: true,
           });
         }
         toast.error(error?.response?.data?.messages, {
           style: {
             background: "#e91e63",
             fontSize: "13px",
             color: "#fff",
           },
         });
       }
     }
   };
  const columns = useMemo(
    () => [
      {
        id: "client_account_number", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "A/C No.",
        accessorFn: (row) => row.client_account_number,
      },
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Account Name",
        accessorFn: (row) => row.account_name,
      },
      {
        id: "withdraw_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row?.withdraw_amount?.toLocaleString(),
      },
      {
        id: "withdraw_transaction_method", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Method",
        accessorFn: (row) => row.withdraw_transaction_method,
      },
      {
        id: "withdraw_transaction_charge", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Charge",
        accessorFn: (row) =>
          parseFloat(row?.withdraw_transaction_charge)?.toLocaleString(),
      },
      {
        id: "account_type", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Account Type",
        accessorFn: (row) => row.account_type,
      },
      {
        id: "withdraw_transaction_timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Timestamp",
        accessorFn: (row) => row.withdraw_transaction_timestamp,
      },
      {
        id: "user", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "System User",
        accessorFn: (row) => row.user,
      },
      {
        id: "withdraw_transaction_status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row.withdraw_transaction_status === "success" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : row.withdraw_transaction_status === "pending" ? (
            <div className="badge badge-info p-1 text-white">Pending</div>
          ) : row.withdraw_transaction_status === "failed" ? (
            <div className="badge badge-danger p-1 text-white">Failed</div>
          ) : (
            ""
          ),
      },
      {
        id: "withdraw_transaction_reverse_status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Reverse Status",
        accessorFn: (row) =>
          row.withdraw_transaction_reverse_status === "ongoing" ? (
            <div className="badge badge-success p-1 text-white">
              Not Reversed
            </div>
          ) : row.withdraw_transaction_reverse_status === "reversed" ? (
            <div className="badge badge-warning p-1 text-white">Reversed</div>
          ) : (
            ""
          ),
      },
      {
        id: "withdraw_transaction_code", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Transaction Code",
        accessorFn: (row) => row.withdraw_transaction_code,
      },
      {
        id: "withdraw_transaction_id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <button
              className="text-secondary font-weight-bold text-xs mr-1"
              onClick={() => handleOpenModal(row?.withdraw_transaction_id)}
            >
              Receipt
            </button>

            <button
              className="btn btn-danger text-white rounded-0 btn-sm p-1"
              onClick={() => {setId(row.withdraw_transaction_id);
                setShow(true);}}
              aria-expanded="false"
            >
              Reverse
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };
  // eslint-disable-next-line
  const [columnFilters1, setColumnFilters1] = useState([]);
  // eslint-disable-next-line
  const [globalFilter1, setGlobalFilter1] = useState("");
  // eslint-disable-next-line
  const [sorting1, setSorting1] = useState([]);
  // eslint-disable-next-line
  const [pagination1, setPagination1] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data: { data: otherData = [], otherMeta } = {},
    isError: otherIsError,
    isRefetching: otherIsRefetching,
    isLoading: otherIsLoading,
    refetch: otherRefetch,
  } = useQuery({
    queryKey: [
      "other-data",
      columnFilters1, // refetch when columnFilters changes
      globalFilter1, // refetch when globalFilter changes
      pagination1.pageIndex, // refetch when pagination.pageIndex changes
      pagination1.pageSize, // refetch when pagination.pageSize changes
      sorting1, // refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = `serverside/withdraws/group`;

      try {
        const response = await axiosPrivate.get(fetchURL, {
          params: {
            start: pagination1.pageIndex * pagination1.pageSize,
            size: pagination1.pageSize,
            filters: JSON.stringify(columnFilters1 ?? []),
            globalFilter: globalFilter1 ?? "",
            sorting: JSON.stringify(sorting1 ?? []),
          },
        });

        // console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/login", { state: { from: location }, replace: true });
        }
        console.error("Axios request failed", error);
        throw error;
      }
    },
    placeholderData: keepPreviousData, // don't go to 0 rows when refetching or paginating to the next page
  });
  return (
    <>
      <Toaster />

      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-5  mt-3 ">
            <div className="card-body p-0">
              <ul className="nav nav-tabs bg-dark" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active text-white text-sm"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Individual Withdraws
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-white text-sm"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Group Withdraws
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <MaterialReactTable
                    data={tableData}
                    columns={columns}
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableRowSelection
                    manualFiltering={true}
                    manualPagination={true}
                    manualSorting={true}
                    enableGrouping
                    enableStickyHeader
                    className="table align-items-justify table-flush p-0 m-0"
                    initialState={{
                      density: "compact",
                      showColumnFilters: false,
                    }}
                    paginationDisplayMode={"pages"}
                    muiPaginationProps={{
                      shape: "rounded",
                      sx: { fontFamily: "Nunito", fontSize: "10px" },
                    }}
                    // state={{ isLoading: isLoading }}
                    muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                    muiTableHeadCellProps={{
                      sx: {
                        fontWeight: "normal",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      },
                    }}
                    muiCircularProgressProps={{
                      color: "secondary",
                      thickness: 5,
                      size: 25,
                    }}
                    muiSkeletonProps={{
                      animation: "pulse",
                      height: 28,
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        fontWeight: "normal",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      },
                    }}
                    muiSearchTextFieldProps={{
                      placeholder: "Search",
                      sx: { fontFamily: "Nunito", fontSize: "12px" },
                    }}
                    renderTopToolbarCustomActions={({ table }) => (
                      <>
                        <div className="btn-group">
                          <button className="btn btn-sm btn-danger rounded-0">
                            <i className="fa fa-file-pdf"></i>
                          </button>

                          <button
                            className="btn btn-sm btn-success rounded-0"
                            disabled={table.getRowModel().rows.length === 0}
                            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                            onClick={() =>
                              handleExportRows(table.getRowModel().rows)
                            }
                          >
                            <i className="fa fa-file-excel"></i>
                          </button>
                          <button className="btn btn-sm btn-info rounded-0">
                            <i className="fa fa-print"></i>
                          </button>
                          <button
                            onClick={refetch}
                            className="btn btn-sm btn-dark rounded-0"
                          >
                            <i className="fa fa-rotate-right"></i>
                          </button>
                        </div>
                      </>
                    )}
                    rowCount={meta?.totalRowCount ?? 0}
                    state={{
                      columnFilters,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <MaterialReactTable
                    data={otherData}
                    columns={columns}
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableRowSelection
                    manualFiltering={true}
                    manualPagination={true}
                    manualSorting={true}
                    enableGrouping
                    enableStickyHeader
                    className="table align-items-justify table-flush p-0 m-0"
                    initialState={{
                      density: "compact",
                      showColumnFilters: false,
                    }}
                    paginationDisplayMode={"pages"}
                    muiPaginationProps={{
                      shape: "rounded",
                      sx: { fontFamily: "Nunito", fontSize: "10px" },
                    }}
                    // state={{ isLoading: isLoading }}
                    muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
                    muiTableHeadCellProps={{
                      sx: {
                        fontWeight: "normal",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      },
                    }}
                    muiCircularProgressProps={{
                      color: "secondary",
                      thickness: 5,
                      size: 25,
                    }}
                    muiSkeletonProps={{
                      animation: "pulse",
                      height: 28,
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        fontWeight: "normal",
                        fontSize: "12px",
                        fontFamily: "Nunito",
                      },
                    }}
                    muiSearchTextFieldProps={{
                      placeholder: "Search",
                      sx: { fontFamily: "Nunito", fontSize: "12px" },
                    }}
                    renderTopToolbarCustomActions={({ table }) => (
                      <>
                        <div className="btn-group">
                          <button className="btn btn-sm btn-danger rounded-0">
                            <i className="fa fa-file-pdf"></i>
                          </button>

                          <button
                            className="btn btn-sm btn-success rounded-0"
                            disabled={table.getRowModel().rows.length === 0}
                            onClick={() =>
                              handleExportRows(table.getRowModel().rows)
                            }
                          >
                            <i className="fa fa-file-excel"></i>
                          </button>
                          <button className="btn btn-sm btn-info rounded-0">
                            <i className="fa fa-print"></i>
                          </button>
                          <button
                            onClick={otherRefetch}
                            className="btn btn-sm btn-dark rounded-0"
                          >
                            <i className="fa fa-rotate-right"></i>
                          </button>
                        </div>
                      </>
                    )}
                    rowCount={otherMeta?.totalRowCount ?? 0}
                    state={{
                      columnFilters1,
                      globalFilter1,
                      otherIsLoading,
                      pagination1,
                      showAlertBanner: otherIsError,
                      showProgressBars: otherIsRefetching,
                      sorting1,
                    }}
                    onColumnFiltersChange={setColumnFilters1}
                    onGlobalFilterChange={setGlobalFilter1}
                    onPaginationChange={setPagination1}
                    onSortingChange={setSorting1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WithdrawReceipt
        showModal={showModal}
        handleClose={handleCloseModal}
        data={modalData}
      />
      <ConfirmModal
        message="Are you sure you want to reverse this transaction? "
        onConfirm={handleDelete}
        onCancel={handleCancel}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default WithdrawGeneral;
