import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import axios from "config/axios";
import fileDownload from "js-file-download";

const LoanCalculator = ({ showModal, handleCloseModal }) => {
  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [calcData, setCalcData] = useState([]);
  const [showModalCalc, setShowModalCalc] = useState(false);
  const onSave = async (data) => {
    let loan_product = data.loan_product.value;
    delete data.loan_product;
    data.loan_product = loan_product;
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post("/loans/calculator", data, {
        signal: controller.signal,
      });
      setCalcData(response?.data?.data);
      setShowModalCalc(true);
      // toast("processing please wait");
      progress.finish();
      setDisabled(false);
      handleCloseModal();
      reset();
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  const [loanProducts, setLoanProducts] = useState([]);
  const controller = new AbortController();
  const getLoanProducts = async () => {
    try {
      const response = await axiosPrivate.get("/settings/loans/products", {
        signal: controller.signal,
      });
      setLoanProducts(response?.data?.data?.loan_products);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoanProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const options = loanProducts?.map((loanProduct) => ({
    value: `${loanProduct.id}`,
    label: `${loanProduct.title} - ${loanProduct.type} - ${loanProduct.interest_rate} % - ${loanProduct.product_interval}`,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  let interest = 0;
  let principal = 0;

  for (let i = 0; i < calcData?.length; i++) {
    interest += calcData[i].interest;
    principal += calcData[i].principal;
  }
  const localData = JSON.parse(localStorage?.user_data)?.sacco_email;
  // console.log(localData)
  const generatePDF = async () => {
    try {
      const data = {
        data: calcData,
        saccoData: localData,
        interest: interest,
        principal: principal,
      };
      console.log(data);
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axios.post(
        `/export/reports/loans/calculator`,
        { data: data },
        { responseType: "blob" },
        {
          signal: controller.signal,
        }
      );
      // console.log(response.data);
      const download_title =
        JSON.parse(localStorage?.user_data).sacco.replace(/\s/g, "") +
        "-loan-calculation.pdf";
      fileDownload(response.data, download_title);
      progress.finish();
      setDisabled(false);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> Loan Calculator </Modal.Title>
            <button onClick={handleCloseModal} className="btn-close btn-danger">
              <i className="fa fa-close"></i>
            </button>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-12">
              <label htmlFor="loan_product" className="form-label">
                Select Loan Products *
              </label>
              <Controller
                control={control}
                name="loan_product"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    {...register("loan_product", { required: true })}
                    className="border-1 form-control-md"
                    placeholder="Select  Loan Product  * "
                    styles={customStyles}
                    options={options}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    isMulti={false}
                    ref={ref}
                  />
                )}
              />
              {errors?.loan_product?.type === "required" && (
                <p className="text-small m-0 text-start text-danger help-block">
                  Please Select Loan Product
                </p>
              )}
            </div>

            <div className="form-group col-md-6 input-container">
              <label htmlFor="amount" className="form-label">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                placeholder="Amount  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("amount", {
                  required: true,
                })}
              />
              {errors?.amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter amount
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="date" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                name="date"
                placeholder="Received Date *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("date", {
                  required: true,
                })}
              />
              {errors?.date?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter the start date
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="period" className="form-label">
                Period
              </label>
              <input
                type="number"
                name="period"
                placeholder="Tenure Period  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("period", {
                  required: true,
                })}
              />
              {errors?.period?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please input the tenure period
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Calculate"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        show={showModalCalc}
        backdrop="static"
        keyboard={false}
        onHide={() => setShowModalCalc(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Loan Calculation{" "}
          </Modal.Title>
          <button
            onClick={() => setShowModalCalc(false)}
            className="btn-close btn-danger"
          >
            <i className="material-icons opacity-10">close</i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fliud">
            <table className="table text-sm table-bordered">
              <thead className="text-center">
                <tr>
                  <th>Period</th>
                  <th>Due Date</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Total Payment</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                {calcData?.map((data) => (
                  <tr key={data.period}>
                    <td className="text-center"> {data?.period} </td>
                    <td className="text-center"> {data?.date} </td>
                    <td className="text-right">
                      {" "}
                      {data?.principal?.toLocaleString()}{" "}
                    </td>
                    <td className="text-right">
                      {" "}
                      {data?.interest?.toLocaleString()}{" "}
                    </td>
                    <td className="text-right">
                      {" "}
                      {data?.payment?.toLocaleString()}{" "}
                    </td>
                    <td className="text-right">
                      {" "}
                      {data?.balance?.toLocaleString()}{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex flex-row-reverse text-dark p-0">
              <div className="py-3 px-2 text-center text-sm">
                <div className="mb-2 text-dark fw-500">Total Amount</div>
                <div className="h6 font-weight-light">
                  {(
                    Math.ceil((interest + principal) / 10) * 10
                  ).toLocaleString()}{" "}
                </div>
              </div>
              <div className="py-3 px-2 text-center text-sm">
                <div className="mb-2">Total Principal</div>
                <div className="h6 font-weight-light">
                  {" "}
                  {(Math.ceil(principal / 10) * 10).toLocaleString()}{" "}
                </div>
              </div>
              <div className="py-3 px-2 text-center text-sm">
                <div className="mb-2">Total Interest</div>
                <div className="h6 font-weight-light">
                  {(Math.ceil(interest / 10) * 10).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-dark"
            onClick={() => setShowModalCalc(false)}
          >
            Close
          </button>
          <button
            disabled={disabled}
            onClick={() => generatePDF(calcData)}
            type="submit"
            className="btn btn-primary"
          >
            {!disabled && "Download"} {disabled && "Please Wait"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoanCalculator;
