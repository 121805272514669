import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import ProgressBar from "@badrap/bar-of-progress";
import Pagination from "pages/components/pagination";

const ApprovedLoansReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });


  const controller = new AbortController();

  const [loans, setLoans] = useState([]);
  //fetch Accounts Accounts data
  const getLoans = async () => {
    progress.start()
    try {
      const response = await axiosPrivate.get(
        "reports/new/loans/process-applications",
        {
          signal: controller.signal,
        }
      );

      setLoans(response.data.data);
      progress.finish();
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const total = loans?.map((data) => data?.amount)?.reduce((a, b) => a + b, 0);

    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loans?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(loans?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header bg-dark text-white">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Approved Loan Applications Report{" "}
                </h2>
              </div>
              <div className="card-body mt-3 mb-3">
                <div className="mt-3">
                  <table className="table table-hover table-bordered text-medium-small">
                    <thead>
                      <tr className="bg-dark text-white text-medium-small">
                        <th>Account No.</th>
                        <th>Client Name</th>
                        <th>Loan Product</th>
                        <th>Loan N0.</th>
                        <th>Date Of Application</th>
                        <th>Amount Applied</th>
                        <th>Tenure Period</th>
                      </tr>
                    </thead>

                    <tbody className="text-medium-small">
                      {currentItems?.map((data) => (
                        <tr key={data?.id}>
                          <td>{data?.account}</td>
                          <td>{data?.client}</td>
                          <td>{data?.product}</td>
                          <td>{data?.code}</td>
                          <td>{data?.date}</td>
                          <td>{data?.amount?.toLocaleString()}</td>
                          <td>{data?.tenure}</td>
                        </tr>
                      ))}
                      <tr className="bg-dark text-white">
                        <th colSpan="5">Total Amount</th>
                        <th>{total?.toLocaleString()}</th>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApprovedLoansReport;
