
import { Link } from "react-router-dom";

const ReportLoans = () => {

  return (
    <>
      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Loans Reports{" "}
                </h2>
              </div>
              <div className="card-body row ">
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Pending Applications
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/pending-applications"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Applications Under Processing
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/processing-applications"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Approved Applications
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/approved-applications"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Rejected Applications
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/rejected-applications"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Active / Disbursed Loans
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/active-loans"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Overdue Loans
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/overdue-loans"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Waived Off Loans
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/waived-off-loans"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Writtern Off Loans
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/writtern-off-loans"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Settled Loans
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/settled-loans"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Aging Loans
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/aging-loans"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Loan Portfolio
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/loans-portfolio"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Loan Balances
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/loan-balances"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Loan Statement
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/loans/loans-statement"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportLoans;
