import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import NewTransfer from "./components/addTransfer";
import Table from "pages/components/common_table";

const Transfers = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const controller = new AbortController();
  const getTransfers = async () => {
    try {
      const response = await axiosPrivate.get("/accounting/transfers/inbound", {
        signal: controller.signal,
      });
      setClients(response.data.data.transactions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Amount",
        size: 50,
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "sendingAccount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Sending A/C Details",
        size: 50,
        accessorFn: (row) =>
          `${row.sendingAccount.account_name} - ${row.sendingAccount.account_type} - ${row.sendingAccount.account_number}`,
      },
      {
        id: "receivingAcccount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Receiving A/C Details",
        size: 50,
        accessorFn: (row) =>
          `${row.receivingAcccount.account_name} - ${row.receivingAcccount.account_type} - ${row.receivingAcccount.account_number}`,
      },

      {
        id: "code", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Transaction Code",
        accessorFn: (row) => row.code,
      },
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Date",
        accessorFn: (row) => row.timestamp,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row.status === "success" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : row.status === "reversed" ? (
            <div className="badge badge-warning p-1 text-white">Reversed</div>
          ) : row.status === "failed" ? (
            <div className="badge badge-success p-1 text-white">Success</div>
          ) : (
            ""
          ),
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to=""
              className="text-secondary font-weight-bold text-xs mr-3"
            >
              Receipt
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      <section className="py-5 mt-3">
      
            <div className="card mb-5 mb-lg-0">
              <NewTransfer
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                getTransfers={getTransfers}
              />
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={clients}
                  headers={
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="h6 mb-0 text-uppercase text-start">
                          Transfers
                        </h2>
                      </div>
                      <div className="col-md-6">
                        {roles?.includes(1000022) && (
                          <button
                            className="btn btn-outline-dark float-end"
                            onClick={handleShowModal}
                          >
                            Make Transfer
                          </button>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
       
      </section>
    </>
  );
};

export default Transfers;
