import "./App.css";
import LoginUser from "pages/authentication/login.js";
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Missing from "layouts/404";
import ForgotPassword from "pages/authentication/forgot-password";
import Dashboard from "pages/dashboards/dashboard";
import RequiredAuth from "middlewares/hooks/requiredLogin";
import PersitLogin from "middlewares/global/persistentLogin";
import IndividualClient from "pages/clients/individuals/individual";
import IndividualSingle from "pages/clients/individuals/individual-single";
import GroupClient from "pages/clients/groups/group";
import IndividualGroupSingle from "pages/clients/groups/group-single";
import SavingGeneral from "pages/accounting/savings/savings";
import WithdrawGeneral from "pages/accounting/withdraws/withdraw";
import FixedDepositPage from "pages/accounting/fixed-deposits/fixeddeposits";
import Transfers from "pages/accounting/transfers/transfers";
import LedgerPosting from "pages/accounting/ledger-posting/postings";
import TopUps from "pages/accounting/topup/topups";
import Incomes from "pages/accounting/incomes/incomes";
import Vendors from "pages/accounting/vendor/vendors";
import Bills from "pages/accounting/bills/bills";
import LoanApplications from "pages/loans/loan-applications/applications";
import LoanApplicationSingle from "pages/loans/loan-applications/application-single";
import BusinessProfile from "pages/settings/user/components/businessprofile";
import SaccoDefaults from "pages/settings/user/components/sacco-systemdefaults";
import SaccoAccounts from "pages/settings/user/components/saccoaccount-component";
import SaccoWthdrawSettings from "pages/settings/user/components/fundwithdrawsettings";
import LoanProducts from "pages/settings/user/components/loan-products";
import PaymentModes from "pages/settings/user/components/payment-modes";
import UserManager from "pages/settings/user/components/user-manager";
import BranchManager from "pages/settings/user/components/branchmanagement";
import CommunicationGeneral from "pages/settings/user/components/communication";
import ReportGeneral from "pages/reports/reports-normal";
import FixedDeposit from "pages/settings/user/components/fixed-deposit-settings";
import ProcessedLoanApplications from "pages/loans/loan-applications/applications-processed";
import ApprovedLoanApplications from "pages/loans/loan-applications/applications-approved";
import ActiveLoanApplications from "pages/loans/loan-applications/applications-active";
import OverdueLoanApplications from "pages/loans/loan-applications/applications-overdue";
import RejectedLoanApplications from "pages/loans/loan-applications/applications-rejected";
import SettledLoanApplications from "pages/loans/loan-applications/applications-settled";
import WaivedOffLoanApplications from "pages/loans/loan-applications/applications-waiveoff";
import WrittenLoanApplications from "pages/loans/loan-applications/applications-written";
import UserActivity from "pages/settings/user/components/user-activity";
import VerifyEmail from "pages/authentication/verify-email";
import UpdatePassword from "pages/authentication/update-password-controller";
import ChangeUserPassword from "pages/settings/user/change_user_password";
import SavingGeneralSacco from "pages/accounting/savings/savings-general";
import WithdrawGeneralSacco from "pages/accounting/withdraws/general-withdraw";
import UserByManager from "pages/accounting/balancer/user-balance";
import TeamSingleBalancer from "pages/accounting/balancer/user-single-balancer";
import AccountBalancesReport from "pages/reports/accounting/account-balances-report";
import LoanProductSingle from "pages/settings/user/components/loan-products-single";
import SharesGeneral from "pages/accounting/shares/shares";
// import LoanPortilio from "pages/reports/loans/loans-portofolio";
// import AgingLoans from "pages/reports/loans/loans-aging-loans";
// import LoanBalances from "pages/reports/loans/loans-balances";
// import OverdueLoansReport from "pages/reports/loans/loans-over-due";
import AuthLayout from "layouts/auth-layout";
import ReportLoans from "pages/reports/reports-normal-loans";
import CashBookReport from "pages/reports/accounting/cashbook";
import GeneralLedger from "pages/reports/accounting/general-ledger";
import TrailBalance from "pages/reports/accounting/trail-balance";
import IncomeStatement from "pages/reports/accounting/income-statement";
import BalanceSheet from "pages/reports/accounting/balance-sheet";
import CreditScore from "pages/reports/accounting/credit-score";
import PendingLoansReport from "pages/reports/loans/pending-loans-report";
import ProcessLoansReport from "pages/reports/loans/proccessing-loans-report";
import ApprovedLoansReport from "pages/reports/loans/approved-loans-report";
import RejectedLoansReport from "pages/reports/loans/rejected-loans-report";
import ActiveLoansReport from "pages/reports/loans/active-loans-report";
import OverdueLoansReport from "pages/reports/loans/overdue-loans-report";
import WaivedOffLoansReport from "pages/reports/loans/waived-off-loans-report";
import WritternOffLoansReport from "pages/reports/loans/writtern-off-loans-report";
import SettledLoansReport from "pages/reports/loans/settled-loans-report";
// import TwoFactorAuthTest from "pages/authentication/two-factor-auth";
function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/" element={<LoginUser />} />
          <Route path="/recover" element={<ForgotPassword />} />
          <Route path="/recover/password/:id" element={<UpdatePassword />} />
          <Route path="/recover/verify" element={<VerifyEmail />} />
          {/* <Route path="/two-factor-auth/verify" element={<TwoFactorAuthTest />} /> */}
          <Route element={<PersitLogin />}>
            <Route element={<RequiredAuth />}>
              <Route element={<AuthLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                  path="clients/individual"
                  element={<IndividualClient />}
                />
                <Route
                  path="clients/individual/:id"
                  element={<IndividualSingle />}
                />
                <Route path="clients/groups" element={<GroupClient />} />
                <Route
                  path="clients/groups/:id"
                  element={<IndividualGroupSingle />}
                />
                <Route path="accounting/savings" element={<SavingGeneral />} />
                <Route path="accounting/shares" element={<SharesGeneral />} />
                <Route
                  path="accounting/general/savings"
                  element={<SavingGeneralSacco />}
                />
                <Route
                  path="accounting/general/withdraws"
                  element={<WithdrawGeneralSacco />}
                />
                <Route path="manage/team" element={<UserByManager />} />
                <Route
                  path="manage/team/:id"
                  element={<TeamSingleBalancer />}
                />
                <Route
                  path="accounting/withdraws"
                  element={<WithdrawGeneral />}
                />
                <Route
                  path="accounting/fixed/deposit"
                  element={<FixedDepositPage />}
                />
                <Route path="accounting/transfers" element={<Transfers />} />
                <Route path="accounting/ledger" element={<LedgerPosting />} />
                <Route path="accounting/topup" element={<TopUps />} />
                <Route path="accounting/incomes" element={<Incomes />} />
                <Route path="accounting/vendors" element={<Vendors />} />
                <Route path="accounting/bills" element={<Bills />} />
                <Route
                  path="loans/applications"
                  element={<LoanApplications />}
                />
                <Route
                  path="loans/processed"
                  element={<ProcessedLoanApplications />}
                />
                <Route
                  path="loans/approved"
                  element={<ApprovedLoanApplications />}
                />
                <Route
                  path="loans/active"
                  element={<ActiveLoanApplications />}
                />
                <Route
                  path="loans/overdue"
                  element={<OverdueLoanApplications />}
                />
                <Route
                  path="loans/rejected"
                  element={<RejectedLoanApplications />}
                />
                <Route
                  path="loans/settled"
                  element={<SettledLoanApplications />}
                />
                <Route
                  path="loans/waived"
                  element={<WaivedOffLoanApplications />}
                />
                <Route
                  path="loans/written"
                  element={<WrittenLoanApplications />}
                />
                <Route
                  path="loans/loans/:id"
                  element={<LoanApplicationSingle />}
                />
                <Route
                  path="settings/business/profile"
                  element={<BusinessProfile />}
                />
                <Route
                  path="settings/sacco/defaults"
                  element={<SaccoDefaults />}
                />
                <Route
                  path="settings/sacco/accounts"
                  element={<SaccoAccounts />}
                />
                <Route
                  path="settings/withdraws"
                  element={<SaccoWthdrawSettings />}
                />
                <Route
                  path="settings/loan/products"
                  element={<LoanProducts />}
                />
                <Route
                  path="settings/payments/mode"
                  element={<PaymentModes />}
                />
                <Route
                  path="settings/user/management"
                  element={<UserManager />}
                />
                <Route
                  path="settings/branch/management"
                  element={<BranchManager />}
                />
                <Route
                  path="communication"
                  element={<CommunicationGeneral />}
                />
                <Route
                  path="settings/fixed/deposits"
                  element={<FixedDeposit />}
                />
                <Route
                  path="settings/loan/products/:id"
                  element={<LoanProductSingle />}
                />
                {/* reports */}
                <Route path="reports/accounting" element={<ReportGeneral />} />
                <Route path="reports/loans" element={<ReportLoans />} />
                <Route
                  path="reports/accounting/general-ledger"
                  element={<GeneralLedger />}
                />
                <Route
                  path="reports/accounting/cashbook"
                  element={<CashBookReport />}
                />
                <Route
                  path="reports/accounting/trial-balance"
                  element={<TrailBalance />}
                />
                <Route
                  path="reports/account-balances"
                  element={<AccountBalancesReport />}
                />
                <Route
                  path="reports/accounting/income-statement"
                  element={<IncomeStatement />}
                />
                <Route
                  path="reports/accounting/balance-sheet"
                  element={<BalanceSheet />}
                />
                <Route
                  path="reports/accounting/credit-score"
                  element={<CreditScore />}
                />
                {/* loans */}
                <Route
                  path="reports/loans/pending-applications"
                  element={<PendingLoansReport />}
                />
                <Route
                  path="reports/loans/processing-applications"
                  element={<ProcessLoansReport />}
                />
                <Route
                  path="reports/loans/approved-applications"
                  element={<ApprovedLoansReport />}
                />
                <Route
                  path="reports/loans/rejected-applications"
                  element={<RejectedLoansReport />}
                />
                <Route
                  path="reports/loans/active-loans"
                  element={<ActiveLoansReport />}
                />
                <Route
                  path="reports/loans/overdue-loans"
                  element={<OverdueLoansReport />}
                />

                <Route
                  path="reports/loans/waived-off-loans"
                  element={<WaivedOffLoansReport />}
                />
                <Route
                  path="reports/loans/writtern-off-loans"
                  element={<WritternOffLoansReport />}
                />
                <Route
                  path="reports/loans/settled-loans"
                  element={<SettledLoansReport />}
                />

                <Route path="profile/user" element={<ChangeUserPassword />} />
                <Route path="activity/user" element={<UserActivity />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
