import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import NewFixedDeposits from "./components/addfixeddeposit";
import Table from "pages/components/common_table";
import TransferFixedDepoists from "./components/checoutfixeddeposit";

const FixedDepositPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [showModal1, setShowModal1] = useState(false);
  const handleShowModal1 = (data) => {setShowModal1(true); setModalData(data)};
  const handleCloseModal1 = () => {setShowModal1(false); setModalData([])};

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const controller = new AbortController();
  const getFixed = async () => {
    try {
      const response = await axiosPrivate.get("/accounting/fixed/deposits", {
        signal: controller.signal,
      });
      setClients(response.data.data.transactions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getFixed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "account", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "A/C No.",
        accessorFn: (row) => row.account_number,
      },
      {
        id: "account_type", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Account Type",
        accessorFn: (row) => row.account,
      },
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Account Number",
        accessorFn: (row) => row.account_name,
      },
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Fixed Amount",
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "return_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount To Receive",
        accessorFn: (row) => row.return_amount.toLocaleString(),
      },
      {
        id: "start", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Start Date",
        accessorFn: (row) => row.start,
      },
      {
        id: "end", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "End Date",
        accessorFn: (row) => row.end,
      },
      {
        id: "code", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Transaction Code",
        size: 50,
        accessorFn: (row) => row.code,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) =>
          row.status === "active" ? (
            <div className="badge badge-success p-1 text-white">Active</div>
          ) : row.status === "inactive" ? (
            <div className="badge badge-warning p-1 text-white">InActive</div>
          ) : (
            ""
          ),
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="btn-group">
            <button
              onClick={() => {
                console.log(row.id);
              }}
              className="btn btn-sm btn-primary rounded-0 font-weight-bold text-small"
            >
              View
            </button>
            {row.status === "active" ? (
              <button
                onClick={() => {
                  handleShowModal1(row);
                }}
                className="text-white btn btn-dark btn-sm rounded-0 font-weight-bold text-small"
              >
                Transfer
              </button>
            ) : (
              <button className="text-white btn btn-info btn-sm rounded-0 font-weight-bold text-small">
                {row?.transfer_status}
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const refetch = () => {};
  return (
    <>
      <section className="py-2 mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <NewFixedDeposits
                getFixed={getFixed}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
              />
              <TransferFixedDepoists
                getFixed={getFixed}
                showModal={showModal1}
                handleCloseModal={handleCloseModal1}
                modalData={modalData}
              />
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={clients}
                  refetch={refetch}
                  headers={
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="h6 mb-0 text-uppercase text-center">
                          Fixed Deposits
                        </h2>
                      </div>
                      <div className="col-md-6">
                        {roles?.includes(1000017) && (
                          <button
                            className="btn btn-outline-dark float-end rounded-0 text-small"
                            onClick={handleShowModal}
                          >
                            Add Fixed Deposit
                          </button>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FixedDepositPage;
