import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";

const IncomeStatement = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const controller = new AbortController();
   const [incomes, setIncomes] = useState([]);
   const [expenses, setExpenses] = useState([]);
   const [dates, setDates] = useState([]);

   const onQuery = async (data) => {
     try {
       progress.start();
       setDisabled(true);
       const response = await axiosPrivate.post(
         `/reports/new/income-statement`,
         data,
         {
           signal: controller.signal,
         }
       );

       setIncomes(response.data.data.data.incomes);
      //  console.log(incomes)
       setExpenses(response.data.data.data.expenses);
       setDates(response.data.data.dates);
       progress.finish();
       setDisabled(false);
       reset();
     } catch (error) {
       console.log(error);
       if (!error?.response) {
         toast.error("No server response");
         progress.finish();
         setDisabled(false);
       } else {
         console.log(error?.response);
         progress.finish();
         setDisabled(false);
         if (error?.response?.status === 401) {
           navigate("/", { state: { from: location }, replace: true });
         }
         toast.error(error?.response?.data?.messages, {
           style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
         });
       }
     }
   };
  //  console.log(incomes)
   const sumIncome = incomes?.map((data) => data?.amount)?.reduce((a, b) => a + b, 0);
   const sumExpenses = expenses?.map((data) => data?.amount)?.reduce((a, b) => a + b, 0);
  return (
    <>
      <Toaster />

      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Profit & Loss{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3 text-medium-small">
          <div className="row">
            <div className="col-md-12 mb-3">
              <form
                className="row needs-validation"
                id="generalLegerForm"
                onSubmit={handleSubmit(onQuery)}
              >
                <div className="form-group col-md-3">
                  <label className="form-label">Select Start Date</label>
                  <input
                    type="date"
                    placeholder="Select Start Date"
                    className="form-control shadow border-1"
                    {...register("start_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.start_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label">Select End Date</label>
                  <input
                    type="date"
                    placeholder="Select End Date"
                    className="form-control shadow border-1"
                    {...register("end_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.end_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>

                <div className="form-group col-md-3 pt-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center rounded-0"
                    disabled={disabled}
                  >
                    {!disabled && "Generate"} {disabled && "Please Wait ....."}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-3 text-medium-small">
            <table className="table table-hover table-bordered text-medium-small">
              <thead>
                <tr>
                  <th className="text-left" colSpan="8">
                    <div className="row">
                      <p className="text-left col-4">
                        Start Date : {dates?.start_date}
                      </p>
                      <p className="text-left col-4">
                        End Date: {dates?.end_date}
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody className="text-medium-small">
                <tr>
                  <th className="bg-dark text-white" colSpan={2}>
                    Income / Revenue
                  </th>
                </tr>
                {incomes.map((vote, index) => (
                  <tr key={index}>
                    <td>{vote?.account}</td>
                    <td>{vote?.amount?.toLocaleString()}</td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-info text-white">Total Income</th>
                  <th className="bg-info text-white">{sumIncome?.toLocaleString()}</th>
                </tr>
                <tr>
                  <th className="bg-dark text-white" colSpan={2}>
                    Expenses
                  </th>
                </tr>
                {expenses.map((vote, index) => (
                  <tr key={index}>
                    <td>{vote?.account}</td>
                    <td>{vote?.amount?.toLocaleString()}</td>
                  </tr>
                ))}
                <tr>
                  <th className="bg-danger text-white">Total Expenses</th>
                  <th className="bg-danger text-white">{sumExpenses?.toLocaleString()}</th>
                </tr>

                <tr>
                  <th className="bg-success text-white">Net Profit</th>
                  <th className="bg-success text-white">
                    {(sumIncome - sumExpenses)?.toLocaleString()}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeStatement;
