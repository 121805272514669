import { Link, useNavigate, useLocation } from "react-router-dom";

import { useState, useMemo, useEffect } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import NewTransfer from "./components/addPosting";
import Table from "pages/components/common_table";

const LedgerPosting = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const controller = new AbortController();
  const getLedgers = async () => {
    try {
      const response = await axiosPrivate.get("/accounting/ledgers/posting", {
        signal: controller.signal,
      });
      setData(response.data.data.transactions);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getLedgers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount",
        accessorFn: (row) => row.amount.toLocaleString(),
      },
      {
        id: "sending_account", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Sending A/C Details",
        accessorFn: (row) => row.sending_account,
      },
      {
        id: "receivingAcccount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Receiving A/C Details",
        accessorFn: (row) => row.receiving_account,
      },

      {
        id: "reference", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Transaction Code",
        accessorFn: (row) => row.reference,
      },
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Date",
        accessorFn: (row) => row.timestamp,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Status",
        accessorFn: (row) => row.status,
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to=""
              className="text-secondary font-weight-bold text-xs mr-3"
            >
              Receipt
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);
  return (
    <>
      <section className="py-2 mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <NewTransfer
                showModal={showModal}
                getLedgers={getLedgers}
                handleCloseModal={handleCloseModal}
              />
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={data}
                  refetch={""}
                  headers={
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="h6 mb-0 text-uppercase text-center">
                          Ledger Transactions
                        </h2>
                      </div>
                      <div className="col-md-6">
                        {roles?.includes(1000020) && (
                          <button
                            className="btn btn-outline-dark float-right"
                            onClick={handleShowModal}
                          >
                            New Ledger Transaction
                          </button>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LedgerPosting;
