
import { useState, useMemo } from "react";
import LoanRepaymentModal from "./loan-repayment-modal";
import Table from "pages/components/common_table";

const LoanSchedule = ({
  data,
  getLoan,
  getloanRepayment,
  isLoading,
  refetch,
}) => {
  // toast("hello")
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = (id) => {
    setShowModal(true);
    setId(id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setId("");
  };
  // console.log(id);

  const loansColumns = useMemo(
    () => [
      {
        accessorKey: "period",
        header: "Period",
        size: 50,
      },
      {
        accessorKey: "date",
        size: 50,
        header: "Date",
      },
      {
        id: "principal",
        header: "Principal",
        size: 50,
        accessorFn: (row) => row.principal.toLocaleString(),
      },
      {
        id: "principal_paid",
        header: "Principal Paid",
        size: 50,
        accessorFn: (row) => row.principal_paid.toLocaleString(),
      },
      {
        id: "interest",
        header: "Interest",
        size: 50,
        accessorFn: (row) => row.interest.toLocaleString(),
      },
      {
        id: "interest_paid",
        header: "Interest Paid",
        size: 50,
        accessorFn: (row) => row.interest_paid.toLocaleString(),
      },

      {
        id: "penalities",
        header: "Penalities",
        size: 50,
        accessorFn: (row) => row.penalities.toLocaleString(),
      },
      {
        id: "penalities_paid",
        header: "Penalities Paid",
        size: 50,
        accessorFn: (row) => row.penalities_paid.toLocaleString(),
      },
      {
        id: "amount_paid",
        header: "Amount Paid",
        size: 50,
        accessorFn: (row) => row.amount_paid.toLocaleString(),
      },
      {
        id: "remaining_balance",
        header: "Balance",
        size: 50,
        accessorFn: (row) =>
          (
            row.interest +
            row.principal +
            row.penalities -
            row.amount_paid
          ).toLocaleString(),
      },
      {
        id: "payment_status",
        size: 50,
        header: "Payment Status",
        accessorFn: (row) =>
          row.payment_status === "paid" ? (
            <badge className="badge badge-success p-2">Paid</badge>
          ) : row.payment_status === "partial" ? (
            <div className="badge badge-info p-2">Parital</div>
          ) : (
            <div className="badge badge-danger p-2">Not Paid</div>
          ),
      },
      {
        id: "activity_status",
        size: 50,
        header: "Activity Status",
        accessorFn: (row) =>
          row.activity_status === "due" ? (
            <div className="badge badge-info p-2">Due</div>
          ) : (
            <div className="badge badge-danger p-2">OverDue</div>
          ),
      },

      {
        id: "id",
        size: 50, //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        accessorFn: (row) => (
          <div className="float-left text-left text-sm">
            <button
              onClick={() => handleOpenModal(row.id)}
              className="btn btn-success text-white rounded-0 btn-sm p-1 mr-2"
            >
              Pay
            </button>
            {/* <button
              className="btn btn-dark text-white rounded-0 btn-sm p-1"
            >
              View
            </button> */}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="row">
        <div className="col-12 p-2">
          <div className="card">
            <div className="card-header">Loan Schedule Details</div>
            <div className="card-body p-0">
              {/* <MaterialReactTable
                columns={loansColumns}
                data={data}
                className="table align-items-center p-0"
                enableColumnFilterModes
                enableColumnOrdering
                enableRowSelection
                enableGrouping
                enableStickyHeader
                initialState={{ density: "compact" }}
                muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontWeight: "normal",
                    fontSize: "13px",
                    fontFamily: "Nunito",
                  },
                }}
                muiSearchTextFieldProps={{
                  placeholder: "Search",
                  sx: { fontFamily: "Nunito", fontSize: "12px" },
                }}
              /> */}
              <Table
                columns={loansColumns}
                data={data}
                isLoading={isLoading}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      </div>
      <LoanRepaymentModal
        getLoan={getLoan}
        id={id}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getloanRepayment={getloanRepayment}
      />
    </>
  );
};

export default LoanSchedule;
