import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here

const Table = ({ columns, data, isLoading, refetch, headers }) => {
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  return (
    <>
      <MaterialReactTable
        data={data}
        columns={columns}
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        // manualPagination={true}
        enableGrouping
        enableStickyHeader
        className="table align-items-justify table-flush p-0 m-0"
        initialState={{
          density: "compact",
          showColumnFilters: false,
        }}
        paginationDisplayMode={"pages"}
        muiPaginationProps={{
          shape: "rounded",
          sx: { fontFamily: "Nunito", fontSize: "10px" },
        }}
        state={{ isLoading: isLoading }}
        muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiCircularProgressProps={{
          color: "secondary",
          thickness: 5,
          size: 25,
        }}
        muiSkeletonProps={{
          animation: "pulse",
          height: 28,
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <div className="btn-group">
              <button className="btn btn-sm btn-danger rounded-0">
                <i className="fa fa-file-pdf"></i>
              </button>

              <button
                className="btn btn-sm btn-success rounded-0"
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
              >
                <i className="fa fa-file-excel"></i>
              </button>
              <button className="btn btn-sm btn-info rounded-0">
                <i className="fa fa-print"></i>
              </button>
              <button
                onClick={refetch}
                className="btn btn-sm btn-dark rounded-0"
              >
                <i className="fa fa-rotate-right"></i>
              </button>
            </div>
            {headers}
          </>
        )}
      />
    </>
  );
};

export default Table;
