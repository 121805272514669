import Logo from "assets/images/img/logo.png";
import {  useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import ProgressBar from "@badrap/bar-of-progress";
import useAuth from "middlewares/hooks/useAuth";
import { useForm } from "react-hook-form";
import axios from "config/axios";
import toast, { Toaster } from "react-hot-toast";
// import NetworkNotification from "services/network/network";
// import errorColor from "services/colors/constants";
const LoginUser = () => {

  const progress = new ProgressBar({ color: "#000", size: 4 });
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const from = location.state?.from?.pathname || "/dashboard";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLoginAction = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axios.post("/auth/sessions", data);
      const accesstoken = response?.data?.data?.access_token;
      const refreshtoken = response?.data?.data?.refresh_token;
      const sessionid = response?.data?.data?.session_id;
      const roles = response?.data?.data?.permissions;
      const user_data = response?.data?.data?.user_data;
      setAuth({ sessionid, accesstoken, refreshtoken });
      const verify = {
        refresh: refreshtoken,
        id: sessionid,
        access: accesstoken,
      };
      localStorage["verify"] = JSON.stringify(verify);
      localStorage["roles"] = JSON.stringify(roles);
      localStorage["user_data"] = JSON.stringify(user_data);
      navigate(from, { replace: true });
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response", {
          style: { background: "#fff", color: "#000" },
        });
        progress.finish();
      } else {
        setDisabled(false);
        progress.finish();
        toast.error(error?.response?.data?.messages, {
          style: { background: "#fff", color: "#000" },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <div className="page-holder-2 vh-100 d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center py-5">
            <div className="col-lg-4 px-lg-4">
              <img
                src={Logo}
                className="img-center text-center"
                height="50px"
                alt="noImageFound"
                loading="lazy"
              />
              <div className="card mt-4 mb-5">
                <div className="card-header">
                  {/* <NetworkNotification /> */}
                  <p className="text-muted text-center">
                    Please Login Into Your Account
                  </p>
                </div>
                <div className="card-body ">
                  <form
                    onSubmit={handleSubmit(onLoginAction)}
                    className="form-horizontal"
                    autoComplete="off"
                  >
                    <div className="form-group mb-4">
                      {errors?.username?.type === "required" && (
                        <p className="text-small  m-0 text-danger help-block">
                          Please provide a valid username
                        </p>
                      )}
                      {errors?.username?.type === "pattern" && (
                        <p className="text-small  m-0 text-danger help-block">
                          Please provide a valid username
                        </p>
                      )}
                      <input
                        type="text"
                        placeholder="Enter Username"
                        className="form-control border-2 form-control-lg"
                        autoComplete="off"
                        autoFocus={true}
                        {...register("username", {
                          required: true,
                          pattern: { value: /^\S+@\S+$/i },
                        })}
                      />
                    </div>
                    <div className="form-group mb-4">
                      {errors?.password?.type === "required" && (
                        <p className="text-small m-0 text-danger help-block">
                          Please provide a password
                        </p>
                      )}
                      <input
                        type="password"
                        placeholder="Enter Password"
                        className="form-control border-2 form-control-lg text-violet"
                        autoComplete="off"
                        autoFocus={true}
                        {...register("password", { required: true })}
                      />
                    </div>
                    {/* <div className="custom-control custom-checkbox ml-1 mb-3">
                      <input type="checkbox" className="custom-control-input" />
                      <label className="custom-control-label">
                        Show password
                      </label>
                    </div> */}
                    <button
                      disabled={disabled}
                      className="btn btn-info btn-block px-5 rounded-5"
                    >
                      {!disabled && "Login"} {disabled && "Authorizing Access"}
                    </button>
                  </form>
                  <p className="text-center mt-3 text-capitalize">
                    {/* <Link to="/recover">Click Here</Link> to recover password */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginUser;
