import Header from "pages/components/header";
import Sidebar from "pages/components/sidebar";
import Footer from "pages/components/footer";
import { Outlet } from "react-router-dom";
const AuthLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="d-flex align-items-stretch">
        <Sidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid p-0">
            {children}
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AuthLayout;
