import { NavLink, Link, useNavigate } from "react-router-dom";
import { memo } from "react";
import useLogout from "middlewares/hooks/useLogout";
// import NetworkNotification from "services/network/network";
const Sidebar = memo(() => {
  
  const roles = JSON.parse(localStorage.roles);
  const logout = useLogout();
  const navigate = useNavigate();
  const signOut = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      await logout();
      await localStorage.removeItem("verify");
      navigate("/");
    }
  };

  return (
    <>
      <div id="sidebar" className="sidebar py-5 sidenav fixed ">
        {/* <div className="text-gray-400 text-uppercase px-3 px-lg-4 py-5 font-weight-bold small headings-font-family">
          MAIN
        </div> */}
        <ul className="sidebar-menu list-unstyled py-5">
          <li className="sidebar-list-item">
            <NavLink to="/dashboard" className="sidebar-link text-muted">
              <i className="o-home-1 mr-3 text-gray"></i>
              Dashboard
            </NavLink>
          </li>

          {roles?.includes(1000001) && (
            <li className="sidebar-list-item text-dark">
              <Link
                data-toggle="collapse"
                data-target="#clients"
                aria-expanded="false"
                aria-controls="clients"
                className="sidebar-link text-muted nav-link"
              >
                <i className="o-user-details-1 mr-3 text-muted"></i>
                <span>Clients / Members </span>
              </Link>
              <div id="clients" className="collapse text-dark">
                <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                  <li className="sidebar-list-item">
                    <NavLink
                      to="/clients/individual"
                      className="sidebar-link text-muted pl-lg-5"
                    >
                      Individual
                    </NavLink>
                  </li>

                  <li className="sidebar-list-item">
                    <NavLink
                      to="/clients/groups"
                      className="sidebar-link text-muted pl-lg-5"
                    >
                      Groups
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {roles?.includes(1000012) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/accounting/savings"
                className="sidebar-link text-muted"
              >
                <i className="o-timing-1 mr-3 text-gray"></i>
                <span>Savings</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000013) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/accounting/withdraws"
                className="sidebar-link text-muted"
              >
                <i className="o-survey-1 mr-3 text-gray"></i>
                <span>Withdraws</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000013) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/accounting/general/savings"
                className="sidebar-link text-muted"
              >
                <i className="o-statistic-1 mr-3 text-gray"></i>
                <span>General Savings</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000013) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/accounting/general/withdraws"
                className="sidebar-link text-muted"
              >
                <i className="o-letter-1 mr-3 text-gray"></i>
                <span>General Withdraws</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000016) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/accounting/shares"
                className="sidebar-link text-muted"
              >
                <i className="o-like-hand-1 mr-3 text-gray"></i>
                <span>Shares</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000016) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/accounting/fixed/deposit"
                className="sidebar-link text-muted"
              >
                <i className="o-sales-up-1 mr-3 text-gray"></i>
                <span>Fixed Deposits</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000018) && (
            <li className="sidebar-list-item">
              <Link
                href="#"
                data-toggle="collapse"
                data-target="#pages"
                aria-expanded="false"
                aria-controls="pages"
                className="sidebar-link text-muted"
              >
                <i className="o-medical-chart-1  mr-3 text-gray"></i>
                <span>Transfers</span>
              </Link>
              <div id="pages" className="collapse">
                <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                  {roles?.includes(1000021) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/accounting/transfers"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Account Transfers
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000019) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/accounting/ledger"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Ledger Posting
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000023) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/accounting/topup"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Account Top Up
                      </NavLink>
                    </li>
                  )}
                  {/* {roles?.includes(1000025) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        className="sidebar-link text-muted pl-lg-5"
                        onClick={() => toast("coming soon, please wait!")}
                      >
                        Shares
                      </NavLink>
                    </li>
                  )} */}
                </ul>
              </div>
            </li>
          )}
          {roles?.includes(1000026) && (
            <li className="sidebar-list-item">
              <Link
                href="#"
                data-toggle="collapse"
                data-target="#income"
                aria-expanded="false"
                aria-controls="income"
                className="sidebar-link text-muted"
              >
                <i className="o-paper-stack-1 mr-3 text-gray"></i>
                <span>Income & Expenses</span>
              </Link>
              <div id="income" className="collapse">
                <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                  {roles?.includes(1000027) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/accounting/incomes"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Incomes
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000029) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/accounting/vendors"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Vendors
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000031) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/accounting/bills"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Bills
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {roles?.includes(1000034) && (
            <li className="sidebar-list-item">
              <Link
                href="#"
                data-toggle="collapse"
                data-target="#loans"
                aria-expanded="false"
                aria-controls="loans"
                className="sidebar-link text-muted"
              >
                <i className="o-presentation-1 mr-3 text-gray"></i>
                <span>Loans</span>
              </Link>
              <div id="loans" className="collapse">
                <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                  {roles?.includes(1000035) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/applications"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Loan Applications
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000037) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/processed"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Processed Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000040) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/approved"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Approved Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000036) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/active"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Active Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000038) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/overdue"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Overdue Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000039) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/rejected"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Rejected Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000041) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/settled"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Settled Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000042) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/waived"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Waived Off Loans
                      </NavLink>
                    </li>
                  )}
                  {roles?.includes(1000043) && (
                    <li className="sidebar-list-item">
                      <NavLink
                        to="/loans/written"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Written Off Loans
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {roles?.includes(1000055) && (
            <li className="sidebar-list-item">
              <Link
                href="#"
                data-toggle="collapse"
                data-target="#settings"
                aria-expanded="false"
                aria-controls="settings"
                className="sidebar-link text-muted"
              >
                <i className="o-settings-window-1 mr-3 text-gray"></i>
                <span>Settings</span>
              </Link>
              <div id="settings" className="collapse">
                <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                  <li className="sidebar-list-item">
                    <NavLink
                      to="/settings/business/profile"
                      className="sidebar-link text-muted pl-lg-5"
                    >
                      Business Profile
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      to="/settings/sacco/defaults"
                      className="sidebar-link text-muted pl-lg-5"
                    >
                      Sacco Defaults
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      to="/settings/sacco/accounts"
                      className="sidebar-link text-muted pl-lg-5"
                    >
                      Accounts
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      className="sidebar-link text-muted pl-lg-5"
                      to="/settings/withdraws"
                    >
                      Withdraw Settings
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      className="sidebar-link text-muted pl-lg-5"
                      to="/settings/fixed/deposits"
                    >
                      Fixed Deposits
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      className="sidebar-link text-muted pl-lg-5"
                      to="/settings/loan/products"
                    >
                      Loan Products
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      className="sidebar-link text-muted pl-lg-5"
                      to="/settings/payments/mode"
                    >
                      Payments
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      className="sidebar-link text-muted pl-lg-5"
                      to="/settings/user/management"
                    >
                      User Managment
                    </NavLink>
                  </li>
                  <li className="sidebar-list-item">
                    <NavLink
                      className="sidebar-link text-muted pl-lg-5"
                      to="/settings/branch/management"
                    >
                      Branch Managment
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {/* {roles?.includes(1000065) && (
            <li className="sidebar-list-item">
              <NavLink
                to="/credit-reference"
                className="sidebar-link text-muted"
              >
                <i className="o-network-1 mr-3 text-gray"></i>
                <span>Credit Reference Bereau</span>
              </NavLink>
            </li>
          )} */}
          {roles?.includes(1000065) && (
            <li className="sidebar-list-item">
              <NavLink to="/communication" className="sidebar-link text-muted">
                <i className="o-repository-1 mr-3 text-gray"></i>
                <span>Communication</span>
              </NavLink>
            </li>
          )}
          {roles?.includes(1000066) && (
            <li className="sidebar-list-item">
              <Link
                href="#"
                data-toggle="collapse"
                data-target="#reports"
                aria-expanded="false"
                aria-controls="reports"
                className="sidebar-link text-muted"
              >
                <i className="o-wireframe-1 mr-3 text-gray"></i>
                <span>Reports</span>
              </Link>
              <div id="reports" className="collapse">
                <ul className="sidebar-menu list-unstyled border-left border-primary border-thick">
                    <li className="sidebar-list-item">
                      <NavLink
                        to="reports/accounting"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Financial Reports
                      </NavLink>
                    </li>
                  
                    <li className="sidebar-list-item">
                      <NavLink
                        to="reports/loans"
                        className="sidebar-link text-muted pl-lg-5"
                      >
                        Loans Reports
                      </NavLink>
                    </li>
                
                
                </ul>
              </div>
            </li>
          )}
          <li className="sidebar-list-item">
            <NavLink to="/manage/team" className="sidebar-link text-muted">
              <i className="o-profile-1 mr-3 text-gray"></i>
              <span>Manage Team </span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/profile/user" className="sidebar-link text-muted">
              <i className="o-mouse-1 mr-3 text-gray"></i>
              <span>Security Settings</span>
            </NavLink>
          </li>
          {/* <li className="sidebar-list-item">
            <NavLink to="/activity/user" className="sidebar-link text-muted">
              <i className="o-id-card-1 mr-3 text-gray"></i>
              <span>Activity</span>
            </NavLink>
          </li> */}
          <li className="sidebar-list-item">
            <Link onClick={signOut} className="sidebar-link text-muted">
              <i className="o-exit-1 mr-3 text-gray"></i>
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
});

export default memo(Sidebar);
