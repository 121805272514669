import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import {  useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";

const TrailBalance = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  const [dates, setDates] = useState([]);
  const [data, setData] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/reports/new/trial-balance`,
        data,
        {
          signal: controller.signal,
        }
      );
      setDates(response?.data?.data?.dates);
      setData(response?.data?.data?.accounts);
      // console.log(data)
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
     

  const sumcredit = data?.filter((data) => data?.type === "credit")?.map((data) => data?.amount)?.reduce((a, b) => a + b, 0);
  const sumdebit = data?.filter((data) => data?.type === "debit")?.map((data) => data?.amount)?.reduce((a, b) => a + b, 0);
  // //   console.log(sumcredit)
  const sortedData = data?.sort((a, b) => new Date(a?.date) - new Date(b?.date));
    // console.log(data)

  return (
    <>
      <Toaster />

      <div className="card mt-2">
        <div className="container-fuild">
          <div className="card">
            <div className="row">
              <div className="col-lg-12">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-header bg-dark">
                    <h2 className="h5 p-1 text-bold text-uppercase text-center text-white">
                      Trial Balance{" "}
                    </h2>
                  </div>
                  <div className="card-body mt-3 mb-3">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <form
                          className="row needs-validation"
                          id="generalLegerForm"
                          onSubmit={handleSubmit(onQuery)}
                        >
                          <div className="form-group col-md-3">
                            <label className="form-label">
                              Select Start Date
                            </label>
                            <input
                              type="date"
                              placeholder="Select Start Date"
                              className="form-control shadow border-1"
                              {...register("start_date", {
                                required: true,
                              })}
                              style={{ height: "45px" }}
                            />
                            {errors?.start_date?.type === "required" && (
                              <p className="text-small m-0 text-danger help-block">
                                This field is required
                              </p>
                            )}
                          </div>
                          <div className="form-group col-md-3">
                            <label className="form-label">
                              Select End Date
                            </label>
                            <input
                              type="date"
                              placeholder="Select End Date"
                              className="form-control shadow border-1"
                              {...register("end_date", {
                                required: true,
                              })}
                              style={{ height: "45px" }}
                            />
                            {errors?.end_date?.type === "required" && (
                              <p className="text-small m-0 text-danger help-block">
                                This field is required
                              </p>
                            )}
                          </div>

                          <div className="form-group col-md-3 pt-3 mt-3">
                            <button
                              type="submit"
                              className="btn btn-primary text-center rounded-0"
                              disabled={disabled}
                            >
                              {!disabled && "Generate"}{" "}
                              {disabled && "Please Wait ....."}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="mt-3 text-medium-small">
                      <table className="table text-medium-small table-hover table-bordered">
                        <thead>
                          <tr>
                            <th colSpan="6">
                              <b className="text-right">
                                From: {dates?.start_date} To: {dates?.end_date}
                              </b>
                            </th>
                          </tr>

                          <tr className="bg-dark text-white">
                            <th>Vote / Account</th>
                            {/* <th>Date</th> */}
                            {/* <th>Comments</th> */}
                            <th>Debit</th>
                            <th>Credit</th>
                          </tr>
                        </thead>
                        <tbody className="text-medium-small">
                          {sortedData?.map((row, index) =>
                            row.type === "credit" ? (
                              <tr key={index}>
                                <td>{row?.account}</td>
                                {/* <td>
                                        {new Date(row?.date).toLocaleDateString(
                                          "en-US",
                                          options
                                        )}
                                      </td> */}
                                {/* <td>{row?.comment}</td> */}
                                <td></td>
                                <td>{row?.amount?.toLocaleString()}</td>
                              </tr>
                            ) : row.type === "debit" ? (
                              <tr key={index}>
                                <td>{row.account}</td>
                                {/* <td>
                                        {new Date(row?.date).toLocaleDateString(
                                          "en-US",
                                          options
                                        )}
                                      </td> */}
                                {/* <td>{row?.comment}</td> */}
                                <td>{row?.amount?.toLocaleString()}</td>
                                <td></td>
                              </tr>
                            ) : (
                              ""
                            )
                          )}
                          {sumcredit !== sumdebit ? (
                            sumcredit > sumdebit ? (
                              <tr>
                                <td>Cash Account</td>
                                <td>-</td>
                                <td>
                                  {Math.abs(
                                    sumdebit - sumcredit
                                  ).toLocaleString()}
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td>Cash Account</td>
                                <td>-</td>
                                <td>
                                  {Math.abs(
                                    sumcredit - sumdebit
                                  ).toLocaleString()}
                                </td>
                              </tr>
                            )
                          ) : (
                            ""
                          )}
                          <tr className="bg-dark text-white"> 
                            <th colSpan={1}>Total</th>
                            {/* <th ></th> */}
                            <th>
                              {sumcredit > sumdebit
                                ? (
                                    sumdebit + Math.abs(sumdebit - sumcredit)
                                  ).toLocaleString()
                                : sumdebit.toLocaleString()}
                            </th>
                            <th>
                              {sumcredit < sumdebit
                                ? (
                                    sumcredit + Math.abs(sumcredit - sumdebit)
                                  ).toLocaleString()
                                : sumcredit.toLocaleString()}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrailBalance;
