
import { useState, useEffect, useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Table from "pages/components/common_table";

const ApprovedLoanApplications = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [loans, setLoans] = useState([]);

  const controller = new AbortController();
  const getLoans = async () => {
    try {
      const response = await axiosPrivate.get("/loans/applications", {
        signal: controller.signal,
      });
      setLoans(response.data.data.loans);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const columns = useMemo(
    () => [
      {
        id: "account_name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Name",
        accessorFn: (row) => row.account_name,
        size: 50,
      },
      {
        id: "account_number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        accessorFn: (row) => row.account_number,
        size: 50,
      },
      {
        id: "amount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Amount",
        accessorFn: (row) => row.amount.toLocaleString(),
        size: 50,
      },
      {
        id: "loan_product", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Loan Product",
        accessorFn: (row) => row.loan_product,
        size: 50,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Status",
        accessorFn: (row) => (row?.status === "pending" ? (
                                          <div className="badge badge-warning p-1 text-white">
                                            Pending
                                          </div>
                                        ) : row?.status === "approved" ? (
                                          <div className="badge badge-info p-1 text-white">
                                            Approved
                                          </div>
                                        ) : row?.status === "disbursed" ? (
                                          <div className="badge badge-success p-1 text-white">
                                            Disbursed
                                          </div>
                                        ) : row?.status === "process" ? (
                                          <div className="badge badge-secondary p-1 text-white">
                                            Processing
                                          </div>
                                        ) : row?.status === "rejected" ? (
                                          <div className="badge badge-danger p-1 text-white">
                                            Rejected
                                          </div>
                                        ) : (
                                          ""
                                        )),
        size: 50,
      },
      {
        id: "date", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Date Of Application",
        accessorFn: (row) => row.date,
        size: 50,
      },
      {
        id: "code", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Loan Number",
        accessorFn: (row) => row.code,
        size: 50,
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 30,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to={`/loans/loans/${row.id}`}
              className="text-secondary font-weight-bold text-xs mr-1"
            >
              view
            </Link>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      
            <section className="py-3 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-2 p-0">
                   
                    <div className="card-body p-0">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active text-dark text-sm"
                            id="home-tab"
                            data-toggle="tab"
                            href="#home"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                          >
                            Individual Loan Applications
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-dark text-sm"
                            id="profile-tab"
                            data-toggle="tab"
                            href="#profile"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                          >
                            Group Loan Applications
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                         <Table 
                          columns={columns}
                            data={loans
                              ?.map((loan) => loan)
                              .filter(
                                (loan) =>
                                  loan.type === "individual" &&
                                  loan.status === "approved"
                              )}
                              headers ={
                                 <h2 className="h6 mb-0 text-uppercase text-center">
                            Approved Loan Applications
                          </h2>  
                              }
                         />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <Table 
                           columns={columns}
                            data={loans
                              ?.map((loan) => loan)
                              .filter(
                                (loan) =>
                                  loan.type === "group" &&
                                  loan.status === "approved"
                              )}
                              headers ={
                                 <h2 className="h6 mb-0 text-uppercase text-center">
                            Approved Loan Applications
                          </h2>  
                              }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
    </>
  );
};

export default ApprovedLoanApplications;
