import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";

const CashBookReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [dates, setDates] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [expenses, setExpenses] = useState([]);
  // const [applicationAmount, setApplicationAmount] = useState([]);
  // const [shares, setShares] = useState([]);
  // const [accountCharges, setAccountCharges] = useState([]);
  // const [loanCharges, setLoanCharges] = useState([]);
  // const [accountTopUp, setAccountTopUp] = useState([]);
  // const [loanRepayment, setLoanRepayment] = useState([]);
  // const [withdrawCharges, setWithdrawCharges] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(`/reports/new/cashbook`, data, {
        signal: controller.signal,
      });
      setDates(response.data.data.dates);
      setIncomes(response.data.data.data.incomes);
      setDeposits(response.data.data.data.deposits);
      setExpenses(response.data.data.data.expenses);
      setWithdraws(response.data.data.data.withdraws);
      // setWithdrawCharges(response.data.data.data.withdrawCharges);

      // setApplicationAmount(response.data.data.data.applications);
      // setShares(response.data.data.data.shares);
      // setAccountCharges(response.data.data.data.accountCharges);
      // setLoanCharges(response.data.data.data.loanCharges);
      // setAccountTopUp(response.data.data.data.accountTop);
      // setLoanRepayment(response.data.data.data.loanRepayment);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  
    
  const datacredit = [
    ...incomes,
    ...deposits,
  ];
  const datadebit = [...expenses, ...withdraws];
  const sumcredit = datacredit
    .filter((data) => data.type === "credit")
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  const sumdebit = datadebit
    .filter((data) => data.type === "debit")
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  //   console.log(sumcredit)
  const sortedCreditData = datacredit.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  const sortedDebitData = datadebit.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  //   console.log(data)
  return (
    <>
      <Toaster />

      <div className="card mt-2">
        <div className="card-header bg-dark">
          <p className="h5 text-bold text-uppercase text-center text-white">
            Cash Book{" "}
          </p>
        </div>
        <div className="card-body mt-3 mb-3 text-medium-small">
          <div className="row">
            <div className="col-md-12 mb-3">
              <form
                className="row needs-validation"
                id="generalLegerForm"
                onSubmit={handleSubmit(onQuery)}
              >
                <div className="form-group col-md-3">
                  <label className="form-label">Select Start Date</label>
                  <input
                    type="date"
                    placeholder="Select Start Date"
                    className="form-control shadow border-1"
                    {...register("start_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.start_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>
                <div className="form-group col-md-3">
                  <label className="form-label">Select End Date</label>
                  <input
                    type="date"
                    placeholder="Select End Date"
                    className="form-control shadow border-1"
                    {...register("end_date", {
                      required: true,
                    })}
                    style={{ height: "45px" }}
                  />
                  {errors?.end_date?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      This field is required
                    </p>
                  )}
                </div>

                <div className="form-group col-md-3 pt-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary text-center rounded-0"
                    disabled={disabled}
                  >
                    {!disabled && "Generate"} {disabled && "Please Wait ....."}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-3 text-medium-small">
            <table className="table table-hover table-bordered">
              <thead className="text-medium-small">
                <tr>
                  <th colSpan="7">
                    <b className="text-right">
                      From: {dates?.start_date} To: {dates?.end_date}
                    </b>
                  </th>
                </tr>
                <tr>
                  <th colSpan="5" className="text-white bg-dark">
                    Cr
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#F6F9FD" }}>
                  <th>Date</th>
                  <th>Reference No.</th>
                  <th>Vote / Account</th>
                  <th>Comments</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody className="text-medium-small">
                {sortedCreditData?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {new Date(data?.date).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>{data?.reference}</td>
                    <td>{data?.account}</td>
                    <td>{data?.comment}</td>
                    <td>{data?.amount?.toLocaleString()}</td>
                  </tr>
                ))}
                <tr className="text-medium-small">
                  <th>Total Credit</th>
                  <th colSpan="3"></th>
                  <th>{sumcredit?.toLocaleString()}</th>
                </tr>
              </tbody>
              <thead className="text-medium-small">
                <tr>
                  <th colSpan="5" className="text-white bg-dark">
                    Dr
                  </th>
                </tr>
                <tr
                  style={{ backgroundColor: "#F6F9FD" }}
                  className="text-medium-small"
                >
                  <th>Date</th>
                  <th>Reference No.</th>
                  <th>Account</th>
                  <th>Comments</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody className="text-medium-small">
                {sortedDebitData?.map((data, index) => (
                  <tr key={index}>
                    <td>
                      {new Date(data?.date).toLocaleDateString(
                        "en-US",
                        options
                      )}
                    </td>
                    <td>{data?.reference}</td>
                    <td>{data?.account}</td>
                    <td>{data?.comment}</td>
                    <td>{data?.amount?.toLocaleString()}</td>
                  </tr>
                ))}
                <tr>
                  <th>Total Debit</th>
                  <th colSpan="3"></th>
                  <th>{sumdebit?.toLocaleString()}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CashBookReport;
