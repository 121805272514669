
import { Link } from "react-router-dom";

const ReportGeneral = () => {

  return (
    <>
      <section className="mt-3">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-5 mb-lg-0">
              <div className="card-header">
                <h2 className="h6 mb-0 text-uppercase text-center">
                  Financial Reports{" "}
                </h2>
              </div>
              <div className="card-body row ">
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">Cash Book</div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/cashbook"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      General Ledger
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/general-ledger"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Trial Balance
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/trial-balance"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Profit & Loss
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/income-statement"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Account Balances Report
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/account-balances"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Balance Sheet
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/balance-sheet"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">
                      Credit Score
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/credit-score"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">Incomes</div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/incomes-general"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <div className="card shadow-lg border-1">
                    <div className="card-header h6 text-center">Expenses</div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <Link
                        to="/reports/accounting/expenses-general"
                        className="btn btn-dark"
                      >
                        Click To View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportGeneral;
