import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "pages/components/footer";
import { useState, useMemo, useCallback, useEffect } from "react";
import NewClient from "./components/add-group";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import toast, { Toaster } from "react-hot-toast";
import Table from "pages/components/common_table";

const GroupClient = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const controller = new AbortController();
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setRoles(JSON.parse(localStorage.roles));
  }, []);

  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("/clients/groups", {
        signal: controller.signal,
      });
      setClients(response.data.data.clients);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDetele = useCallback(
    (id) => {
      confirmAlert({
        // : 'Confirm to submit',
        message: " Are you sure to do this..",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const controller = new AbortController();
                const response = await axiosPrivate.delete(
                  "/clients/group/" + id,
                  {
                    signal: controller.signal,
                  }
                );
                toast.success(response?.data?.messages);
              } catch (error) {
                if (!error?.response) {
                  toast("No server response");
                } else {
                  console.log(error?.response);
                  if (error?.response?.status === 401) {
                    navigate("/", {
                      state: { from: location },
                      replace: true,
                    });
                  }
                  toast.error(error?.response?.data?.messages, {
                    style: {
                      background: "#e91e63",
                      fontSize: "13px",
                      color: "#fff",
                    },
                  });
                }
              }
            },
          },
          {
            label: "No",
            // onClick: () => alert('Click No')
          },
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const columns = useMemo(
    () => [
      {
        id: "account", //access nested data with dot notation
        enableClickToCopy: true,
        header: "A/C No.",
        accessorFn: (row) => row.account,
      },
      {
        id: "title", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account Group",
        accessorFn: (row) => row.title,
      },
      {
        id: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Contact",
        accessorFn: (row) => row.contact,
      },
      {
        id: "address", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Address",
        accessorFn: (row) => row.address,
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to={`/clients/groups/${row.id}`}
              className="text-secondary font-weight-bold text-xs mr-3"
            >
              view
            </Link>
            {roles?.includes(1000003) && (
              <button
                className="btn btn-link text-white rounded-0 btn-sm p-0"
                onClick={() => handleDetele(row.id)}
                aria-expanded="false"
              >
                <i className="text-danger fa fa fa-trash"></i>
              </button>
            )}
          </div>
        ),
      },
    ],
    [handleDetele, roles]
  );
  const refetch = async() => {
    setIsLoading(true);
    await getClients();
  }
  return (
    <>
      <Toaster />

      <section className="mt-3 mb-5">
        <div className="card mb-0 mb-lg-0">
          <div className="card-body p-0">
            <Table
              columns={columns}
              isLoading={isLoading}
              refetch={refetch}
              data={clients}
              headers={
                <div className="row">
                  <div className="col-md-5">
                    <h2 className="h6 text-uppercase text-start">
                      Groups
                    </h2>
                  </div>
                  <div className="col-md-7">
                    <button
                      className="btn btn-outline-dark text-small rounded-0"
                      onClick={handleShowModal}
                    >
                    Add New Group
                    </button>

                    <NewClient
                      getClients={getClients}
                      showModal={showModal}
                      handleCloseModal={handleCloseModal}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </section>

      {/* Conditionally render the modal based on the state variable */}
      <Footer />
    </>
  );
};

export default GroupClient;
